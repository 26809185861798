<header-share></header-share>

<div class="top-bg"></div>

<section id="main-section">
  <div class="section-inner container">
    <div class="stain-bg">
      <!-- <img src="assets/imgs/stain_bg.png" alt="" /> -->
    </div>
    <!-- 모담 about -->
    <div class="about">
      <p class="sub-logo">
        <img src="assets/imgs/sub_logo.png" alt="" />
      </p>
      <h2>3 Think about MODAM</h2>
      <p class="descript-text">모담은 다음의 3가지를 우선시합니다</p>
      <ul>
        <li>
          <img src="assets/imgs/about_icon01.png" alt="" />
          <p>Naturalism</p>
        </li>
        <li class="about-item-center">
          <img src="assets/imgs/about_icon02.png" alt="" />
          <p>Masterpiece</p>
        </li>
        <li>
          <img src="assets/imgs/about_icon03.png" alt="" />
          <p>Mother’s love</p>
        </li>
      </ul>
    </div>
    <!-- // 모담 about -->

    <!-- 모담의 제품들 -->
    <div class="product">
      <h2>모담의 제품들</h2>
      <p class="descript-text">자연보다 더 자연스러운 모담의 제품입니다</p>
      <div class="product-items">
        <div class="item">
          <img src="assets/imgs/product_img01.jpg" alt="" />
          <p class="product-name">2step 아슈와간다<br />발효 마스크팩</p>
          <p>인도의 인삼이라고 불리는 아슈와간다로 만든 발효 마스크팩</p>
          <a href="/product-detail?type=ash" class="more-btn">더보기</a>
        </div>
        <div class="item">
          <img src="assets/imgs/product_img02.jpg" alt="" />
          <p class="product-name">천연 발효 숙성<br />모담 고체 샴푸바</p>
          <p>특허받은 발효숙성 기술로 만든프리미엄 두피 케어 제품</p>
          <a href="/product-detail?type=shampoo" class="more-btn">더보기</a>
        </div>
        <div class="item">
          <img src="assets/imgs/product_img03.jpg" alt="" />
          <p class="product-name">2step 로즈<br />앱솔루트 마스크팩</p>
          <p>
            고가의 불가리아산 로즈 앱솔루트 오일, 유기농 로즈워터가 함유된
            천연향 100% 장미 마스크팩
          </p>
          <a href="/product-detail?type=rose" class="more-btn">더보기</a>
        </div>
        <!-- <div class="item">
          <img src="assets/imgs/product_img04.jpg" alt="" />
          <p class="product-name">달팽이<br />골드세럼 & 크림</p>
          <p>
            홍삼을 먹여 키운 달팽이로 유래한 점액을 함유해 상처치유,피부
            재생효과, 자극 완화 효과가 뛰어난 제품
          </p>
          <a href="/product-detail?type=snail" class="more-btn">더보기</a>
        </div> -->
      </div>
      <p class="message">
        자연의 자정능력으로 인체에 있던 고민을 해결하고자 합니다<span
          class="br"
        ></span>
        우리의 지치고 아픈 몸을 자연이 가지고 있는 능력으로 치유하고 활기를
        되찾고자 합니다<span class="br"></span>
        모담은 그런 자연의 능력을 모두 담았습니다
      </p>
    </div>
    <!-- // 모담의 제품들 -->
  </div>
</section>

<section>
  <!-- SNS -->
  <div class="sns container">
    <div id="insta-wrap" class="insta">
      <div class="info">
        <img src="assets/imgs/insta_logo.png" alt="" />
        <div class="info-text">
          <p class="sns-name">INSTAGRAM</p>
          <p>(주)모담글로벌네이처 공식 인스타그램 @modam_gn</p>
        </div>
      </div>
      <div id="content-social" style="display: none"></div>
      <div class="content-social">
        <div class="cont-inner">
          <a
            href="javascript:void(0)"
            class="cont-item -first"
            target="_blank"
            (click)="$event.stopPropagation()"
          >
            <img src="assets/imgs/insta_img01.png" alt="" />
            <span class="insta-cont">
              <span class="cont-inner">
                <!-- <span class="insta-date">2021 . 01 . 20</span> -->
                <span class="insta-text -txtfirst">
                  <!-- 모담 x QVC Japan 20주년 특집 방송 기념
                  마스크팩 증정 이벤트🎉 <br />
                  모담의 대표상품인 ‘천연 발효 숙성 고체
                  샴푸바’의 인기는 일본에서도 계속 됩니다! -->
                </span>
                <span class="more-text">더보기 >></span>
              </span>
            </span>
          </a>
          <ng-skeleton
            class="cont-item -first"
            [skelHeight]="300"
            [skelWidth]="300"
          ></ng-skeleton>
        </div>
        <div class="cont-inner">
          <a
            href="javascript:void(0)"
            class="cont-item -second"
            target="_blank"
            (click)="$event.stopPropagation()"
          >
            <img src="assets/imgs/insta_img02.png" alt="" />
            <span class="insta-cont">
              <span class="cont-inner">
                <!-- <span class="insta-date">2021 . 01 . 20</span> -->
                <span class="insta-text -txtsecond">
                  <!-- 모담 x QVC Japan 20주년 특집 방송 기념
                  마스크팩 증정 이벤트🎉 <br />
                  모담의 대표상품인 ‘천연 발효 숙성 고체
                  샴푸바’의 인기는 일본에서도 계속 됩니다! -->
                </span>
                <span class="more-text">더보기 >></span>
              </span>
            </span>
          </a>
          <ng-skeleton
            class="cont-item -second"
            [skelHeight]="300"
            [skelWidth]="300"
          ></ng-skeleton>
        </div>
        <div class="cont-inner">
          <a
            href="javascript:void(0)"
            class="cont-item -third"
            target="_blank"
            (click)="$event.stopPropagation()"
          >
            <img src="assets/imgs/insta_img03.png" alt="" />
            <span class="insta-cont">
              <span class="cont-inner">
                <!-- <span class="insta-date">2021 . 01 . 20</span> -->
                <span class="insta-text -txtthird">
                  <!-- 모담 x QVC Japan 20주년 특집 방송 기념
                  마스크팩 증정 이벤트🎉 <br />
                  모담의 대표상품인 ‘천연 발효 숙성 고체
                  샴푸바’의 인기는 일본에서도 계속 됩니다! -->
                </span>
                <span class="more-text">더보기 >></span>
              </span>
            </span>
          </a>
          <ng-skeleton
            class="cont-item -third"
            [skelHeight]="300"
            [skelWidth]="300"
          ></ng-skeleton>
        </div>
      </div>
    </div>
    <div class="youtube">
      <div class="info">
        <img src="assets/imgs/youtube_logo.png" alt="" />
        <div class="info-text">
          <p class="sns-name">YOUTUBE</p>
          <p>(주)모담글로벌네이처 공식 유튜브 MODAM Global Nature Co.,Ltd.</p>
        </div>
      </div>
      <div class="content-video">
        <!-- 임시 썸네일 이미지 파일입니다. 차후에 유튜브 영상이 들어갑니다. -->
        <div class="first-video">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/Xq5XAp6yR_M?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/0k00mIwxIEI?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
  <!-- // SNS -->
</section>

<!-- 모담의 주요성분 -->
<section id="ingredient-wrap">
  <div class="ingredient container">
    <h2>모담의 주요성분</h2>
    <div class="ingredient-inner">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="img-area">
              <img src="assets/imgs/ingredient_icon01.png" alt="" class="off" />
              <img
                src="assets/imgs/ingredient_on_icon01.png"
                alt=""
                class="on"
              />
            </div>
            <p class="ingr-name">코코넛 오일</p>
            <p class="ingr-descript">
              손상된 피부 복구 및 복원,<span class="br"></span>피부 장벽 유지
            </p>
          </div>
          <div class="swiper-slide">
            <div class="img-area">
              <img src="assets/imgs/ingredient_icon02.png" alt="" class="off" />
              <img
                src="assets/imgs/ingredient_on_icon02.png"
                alt=""
                class="on"
              />
            </div>
            <p class="ingr-name">올리브 오일</p>
            <p class="ingr-descript">
              부드러운 피부결 관리 및<span class="br"></span>영양공급
            </p>
          </div>
          <div class="swiper-slide">
            <div class="img-area">
              <img src="assets/imgs/ingredient_icon03.png" alt="" class="off" />
              <img
                src="assets/imgs/ingredient_on_icon03.png"
                alt=""
                class="on"
              />
            </div>
            <p class="ingr-name">팜 오일</p>
            <p class="ingr-descript">
              비타민E가 풍부하며<span class="br"></span>보습에 효과적
            </p>
          </div>
          <div class="swiper-slide">
            <div class="img-area">
              <img src="assets/imgs/ingredient_icon04.png" alt="" class="off" />
              <img
                src="assets/imgs/ingredient_on_icon04.png"
                alt=""
                class="on"
              />
            </div>
            <p class="ingr-name">동백 오일</p>
            <p class="ingr-descript">
              보습에 효과적이며<span class="br"></span>모발에 윤기를 줌
            </p>
          </div>
          <div class="swiper-slide">
            <div class="img-area">
              <img src="assets/imgs/ingredient_icon05.png" alt="" class="off" />
              <img
                src="assets/imgs/ingredient_on_icon05.png"
                alt=""
                class="on"
              />
            </div>
            <p class="ingr-name">모링가 추출물</p>
            <p class="ingr-descript">
              콜라겐 섬유재생을 도움,<span class="br"></span>주름과 피부 트러블
              개선
            </p>
          </div>
          <div class="swiper-slide">
            <div class="img-area">
              <img src="assets/imgs/ingredient_icon06.png" alt="" class="off" />
              <img
                src="assets/imgs/ingredient_on_icon06.png"
                alt=""
                class="on"
              />
            </div>
            <p class="ingr-name">소나무잎 추출물</p>
            <p class="ingr-descript">피부 표면 보호 및 보습</p>
          </div>
          <div class="swiper-slide">
            <div class="img-area">
              <img src="assets/imgs/ingredient_icon07.png" alt="" class="off" />
              <img
                src="assets/imgs/ingredient_on_icon07.png"
                alt=""
                class="on"
              />
            </div>
            <p class="ingr-name">회향잎 추출물</p>
            <p class="ingr-descript">
              피부 활력을 부여하는<span class="br"></span>컨디셔닝 효과
            </p>
          </div>
          <div class="swiper-slide">
            <div class="img-area">
              <img src="assets/imgs/ingredient_icon08.png" alt="" class="off" />
              <img
                src="assets/imgs/ingredient_on_icon08.png"
                alt=""
                class="on"
              />
            </div>
            <p class="ingr-name">흑삼 추출물</p>
            <p class="ingr-descript">
              기미, 주근깨 등 색소 침착 예방<span class="br"></span>피부 미백에
              도움
            </p>
          </div>
          <div class="swiper-slide">
            <div class="img-area">
              <img src="assets/imgs/ingredient_icon09.png" alt="" class="off" />
              <img
                src="assets/imgs/ingredient_on_icon09.png"
                alt=""
                class="on"
              />
            </div>
            <p class="ingr-name">녹차 추출물</p>
            <p class="ingr-descript">
              기미, 주근깨 등 색소 침착 예방<span class="br"></span>피부 미백에
              도움
            </p>
          </div>
          <div class="swiper-slide">
            <div class="img-area">
              <img src="assets/imgs/ingredient_icon10.png" alt="" class="off" />
              <img
                src="assets/imgs/ingredient_on_icon10.png"
                alt=""
                class="on"
              />
            </div>
            <p class="ingr-name">쑥 추출물</p>
            <p class="ingr-descript">
              노화 예방, 피부재생 촉진 향균,<span class="br"></span>소독효과가
              탁월
            </p>
          </div>
          <div class="swiper-slide">
            <div class="img-area">
              <img src="assets/imgs/ingredient_icon11.png" alt="" class="off" />
              <img
                src="assets/imgs/ingredient_on_icon11.png"
                alt=""
                class="on"
              />
            </div>
            <p class="ingr-name">흑마늘 추출물</p>
            <p class="ingr-descript">항산화 효과</p>
          </div>
          <div class="swiper-slide">
            <div class="img-area">
              <img src="assets/imgs/ingredient_icon12.png" alt="" class="off" />
              <img
                src="assets/imgs/ingredient_on_icon12.png"
                alt=""
                class="on"
              />
            </div>
            <p class="ingr-name">데이지꽃 추출물</p>
            <p class="ingr-descript">피부 미백, 강력한 항산화 효과</p>
          </div>
          <div class="swiper-slide">
            <div class="img-area">
              <img src="assets/imgs/ingredient_icon13.png" alt="" class="off" />
              <img
                src="assets/imgs/ingredient_on_icon13.png"
                alt=""
                class="on"
              />
            </div>
            <p class="ingr-name">대자호</p>
            <p class="ingr-descript">항염 및 항산화 효과</p>
          </div>
          <div class="swiper-slide">
            <div class="img-area">
              <img src="assets/imgs/ingredient_icon14.png" alt="" class="off" />
              <img
                src="assets/imgs/ingredient_on_icon14.png"
                alt=""
                class="on"
              />
            </div>
            <p class="ingr-name">감나무잎 추출물</p>
            <p class="ingr-descript">피부 활력 및 컨디셔닝 케어</p>
          </div>
          <div class="swiper-slide">
            <div class="img-area">
              <img src="assets/imgs/ingredient_icon15.png" alt="" class="off" />
              <img
                src="assets/imgs/ingredient_on_icon15.png"
                alt=""
                class="on"
              />
            </div>
            <p class="ingr-name">페퍼민트 추출물</p>
            <p class="ingr-descript">가려움 및 피부염 제거 효과</p>
          </div>
          <div class="swiper-slide">
            <div class="img-area">
              <img src="assets/imgs/ingredient_icon16.png" alt="" class="off" />
              <img
                src="assets/imgs/ingredient_on_icon16.png"
                alt=""
                class="on"
              />
            </div>
            <p class="ingr-name">로즈마리잎 추출물</p>
            <p class="ingr-descript">
              두피개선, 모발성장 촉진,<span class="br"></span>비듬 및 탈모방지에
              탁월
            </p>
          </div>
          <div class="swiper-slide">
            <div class="img-area">
              <img src="assets/imgs/ingredient_icon17.png" alt="" class="off" />
              <img
                src="assets/imgs/ingredient_on_icon17.png"
                alt=""
                class="on"
              />
            </div>
            <p class="ingr-name">아슈와간다</p>
            <p class="ingr-descript">
              콜라겐 생성 촉진 및<span class="br"></span>노화방지에 도움
            </p>
          </div>
          <div class="swiper-slide">
            <div class="img-area">
              <img src="assets/imgs/ingredient_icon18.png" alt="" class="off" />
              <img
                src="assets/imgs/ingredient_on_icon18.png"
                alt=""
                class="on"
              />
            </div>
            <p class="ingr-name">시카카이</p>
            <p class="ingr-descript">
              모발 수분 공급 및 두피건강,<span class="br"></span>청결 도움
            </p>
          </div>
        </div>
        <!-- If we need navigation buttons -->
        <div
          class="swiper-button-prev swiper-button"
          (click)="$event.stopPropagation()"
        ></div>
        <div
          class="swiper-button-next swiper-button"
          (click)="$event.stopPropagation()"
        ></div>

        <!-- <div class="swiper-pagination"></div> -->
      </div>
    </div>
  </div>
</section>

<!-- Contact Us -->
<section id="contact-wrap">
  <div class="contact container">
    <div class="contact-img">
      <img src="assets/imgs/contact_img.png" alt="" />
      <span class="contact-cont">
        <span class="cont-inner">
          <span class="contact-title">Head Office</span>
          <span class="contact-address">
            서울특별시 강남구<br />
            (도로명주소) 삼성로 76길 23-13 102호<br />
            (지번주소)대치동 955-3 102호
          </span>
          <span class="remains-info">
            02-3453-7133<br />
            ohjoo114@daum.net</span
          >
        </span>
      </span>
    </div>
    <div class="text-area">
      <p class="company-name">Modam</p>
      <h2>Contact Us</h2>
      <p class="contact-message">
        모담에 문의하시면 언제든지 친절하게 상담해드립니다
      </p>
      <a href="/contact" class="more-btn">더보기</a>
    </div>
  </div>
</section>
<!-- // Contact Us -->

<!-- 하단 배경 이미지 영역 -->
<div class="bottom-bg"></div>

<footer-share></footer-share>
