<header-share></header-share>

<section class="lab-wrap">
  <top-title
    title="MODAM Lab"
    sub="천연성분만을 고집하는 모담의 연구소입니다"
    secondLine="모담의 모든 제품의 시작은 이 연구소에서 출발합니다"
  ></top-title>

  <article>
    <div class="container">
      <ul class="tab-nav filter-button-group">
        <li data-filter="*">
          <a
            href="javascript:void(0)"
            (click)="$event.stopPropagation()"
            class="active"
            >ALL</a
          >
        </li>
        <li data-filter=".ingredients">
          <a href="javascript:void(0)" (click)="$event.stopPropagation()"
            >Ingredients Materials</a
          >
        </li>
        <li data-filter=".lab">
          <a href="javascript:void(0)" (click)="$event.stopPropagation()"
            >Lab</a
          >
        </li>
        <li data-filter=".products">
          <a href="javascript:void(0)" (click)="$event.stopPropagation()"
            >Products</a
          >
        </li>
      </ul>

      <div class="item-list">
        <div class="lab-item ingredients" data-category="ingredients">
          <div class="img-box">
            <img src="assets/imgs/lab_list_img01.jpg" alt="" />
            <div class="sub-more">
              <a
                href="assets/imgs/lab_list_img01.jpg"
                data-fancybox="images"
                data-caption="천연 한방 재료"
                >더보기</a
              >
            </div>
          </div>
          <p class="item-name">천연 한방 재료</p>
          <p class="item-category">Ingredients Materials</p>
        </div>
        <div class="lab-item ingredients" data-category="ingredients">
          <div class="img-box">
            <img src="assets/imgs/lab_list_img02.jpg" alt="" />
            <div class="sub-more">
              <a
                href="assets/imgs/lab_list_img02.jpg"
                data-fancybox="images"
                data-caption="천연 발효 숙성"
                >더보기</a
              >
            </div>
          </div>
          <p class="item-name">천연 발효 숙성</p>
          <p class="item-category">Ingredients Materials</p>
        </div>
        <div class="lab-item ingredients" data-category="ingredients">
          <div class="img-box">
            <img src="assets/imgs/lab_list_img03.jpg" alt="" />
            <div class="sub-more">
              <a
                href="assets/imgs/lab_list_img03.jpg"
                data-fancybox="images"
                data-caption="천연 발효 숙성"
                >더보기</a
              >
            </div>
          </div>
          <p class="item-name">천연 발효 숙성</p>
          <p class="item-category">Ingredients Materials</p>
        </div>
        <div class="lab-item lab" data-category="lab">
          <div class="img-box">
            <img src="assets/imgs/lab_list_img04.jpg" alt="" />
            <div class="sub-more">
              <a
                href="assets/imgs/lab_list_img04.jpg"
                data-fancybox="images"
                data-caption="발효중인 샴푸바"
                >더보기</a
              >
            </div>
          </div>
          <p class="item-name">발효중인 샴푸바</p>
          <p class="item-category">Lab</p>
        </div>
        <div class="lab-item lab" data-category="lab">
          <div class="img-box">
            <img src="assets/imgs/lab_list_img05.jpg" alt="" />
            <div class="sub-more">
              <a
                href="assets/imgs/lab_list_img05.jpg"
                data-fancybox="images"
                data-caption="항아리 발효 숙성"
                >더보기</a
              >
            </div>
          </div>
          <p class="item-name">항아리 발효 숙성</p>
          <p class="item-category">Lab</p>
        </div>
        <div class="lab-item products" data-category="products">
          <div class="img-box">
            <img src="assets/imgs/lab_list_img06.jpg" alt="" />
            <div class="sub-more">
              <a
                href="assets/imgs/lab_list_img06.jpg"
                data-fancybox="images"
                data-caption="비누 제작 과정"
                >더보기</a
              >
            </div>
          </div>
          <p class="item-name">비누 제작 과정</p>
          <p class="item-category">Products</p>
        </div>
        <div class="lab-item products" data-category="products">
          <div class="img-box">
            <img src="assets/imgs/lab_list_img07.jpg" alt="" />
            <div class="sub-more">
              <a
                href="assets/imgs/lab_list_img07.jpg"
                data-fancybox="images"
                data-caption="샴푸바 완성품"
                >더보기</a
              >
            </div>
          </div>
          <p class="item-name">샴푸바 완성품</p>
          <p class="item-category">Products</p>
        </div>
        <div class="lab-item products" data-category="products">
          <div class="img-box">
            <img src="assets/imgs/lab_list_img08.jpg" alt="" />
            <div class="sub-more">
              <a
                href="assets/imgs/lab_list_img08.jpg"
                data-fancybox="images"
                data-caption="샴푸바 완성품"
                >더보기</a
              >
            </div>
          </div>
          <p class="item-name">샴푸바 완성품</p>
          <p class="item-category">Products</p>
        </div>
      </div>
    </div>
  </article>
</section>

<footer-share></footer-share>
