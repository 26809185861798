<header>
  <div class="container">
    <h1 class="logo">
      <a href="/home" class="web-logo">
        <img src="assets/imgs/logo.png" alt="" />
      </a>
      <a href="/home" class="mobile-logo">
        <img src="assets/imgs/logo.png" alt="" />
      </a>
    </h1>
    <!-- <div class="language">
      <p (click)="languageMenuToggle(); $event.stopPropagation()">Language</p>
      <ul class="language-list">
        <li><a href="javascript:void(0)">English</a></li>
        <li><a href="javascript:void(0)">日本語</a></li>
        <li><a href="javascript:void(0)">中文</a></li>
        <li><a href="javascript:void(0)">DEUTSCH</a></li>
      </ul>
    </div> -->
    <!-- WEB nav -->
    <nav class="web-nav">
      <ul>
        <li class="nav-item nav-about">
          <a href="/about">About 모담</a>
          <div class="sub-menu-wrap">
            <ul class="sub-menu">
              <li>
                <a href="/about">About 모담</a>
              </li>
              <li>
                <a href="/lab">모담 연구소</a>
              </li>
              <li>
                <a href="/global">글로벌 모담</a>
              </li>
            </ul>
          </div>
        </li>
        <li class="nav-item">
          <a href="/product">제품 소개</a>
          <div class="sub-menu-wrap">
            <!-- <ul class="sub-menu">
              <li>
                <a href="/product-detail?type=shampoo">
                  천연 발효 숙성 모담 고체 샴푸바</a
                >
              </li>
              <li>
                <a href="/product-detail?type=ash">
                  모담 2Step 아슈와간다 발효 마스크</a
                >
              </li>
              <li>
                <a href="/product-detail?type=rose"
                  >모담 2Step 로즈 앱솔루트 마스크</a
                >
              </li>
              <li>
                <a href="/product-detail?type=cica"
                  >모담 3Step 아슈와간다 발효 마스크<br />( 시카 )</a
                >
              </li>
              <li>
                <a href="/product-detail?type=eyePatch"
                  >모담 3Step 아슈와간다 발효 마스크<br />( 아이패치 )</a
                >
              </li>
              <li>
                <a href="/product-detail?type=shampooLiquid">모담 샴푸액</a>
              </li>
              <li>
                <a href="/product-detail?type=handCream"
                  >2in1 로즈퍼퓸 워터드롭<br />핸드크림&립밤</a
                >
              </li>
              <li>
                <a href="/product-detail?type=snail"
                  >홍삼달팽이 골드세럼 & 크림</a
                >
              </li>
            </ul> -->
            <ul class="sub-menu">
              <li class="sub-hair">
                <a href="javascript:void(0)">헤어케어</a>
                <div class="sub-in-menu-wrap">
                  <ul class="sub-in-menu">
                    <li>
                      <a href="/product-detail?type=shampoo">고체 샴푸바</a>
                    </li>
                    <li><a href="javascript:void(0)">린스바</a></li>
                    <li>
                      <a href="/product-detail?type=shampooLiquid">샴푸액</a>
                    </li>
                    <li><a href="javascript:void(0)">헤어 에센스</a></li>
                    <li><a href="javascript:void(0)">헤어 미스트</a></li>
                  </ul>
                </div>
              </li>
              <li>
                <a href="javascript:void(0)"
                  >페이셜케어
                  <div class="sub-in-menu-wrap">
                    <ul class="sub-in-menu">
                      <li>
                        <a href="javascript:void(0)">아슈와간다 </a>
                        <div class="last-in-menu-wrap">
                          <ul class="last-in-menu">
                            <li>
                              <a href="/product-detail?type=ash"
                                >2Step 아슈와간다 발효 마스크</a
                              >
                            </li>
                            <li>
                              <a href="/product-detail?type=eyePatch"
                                >3Step 아슈와간다 발효 마스크(아이패치)</a
                              >
                            </li>
                            <li>
                              <a href="/product-detail?type=cica"
                                >3Step 아슈와간다 발효 마스크(시카)</a
                              >
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <a href="javascript:void(0)">장미</a>
                        <div class="last-in-menu-wrap">
                          <ul class="last-in-menu">
                            <li>
                              <a href="/product-detail?type=rose"
                                >2Step 로즈 앱솔루트 마스크</a
                              >
                            </li>
                            <li>
                              <a href="javascript:void(0)">로즈 미스트</a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <!-- <li>
                        <a href="javascript:void(0)">홍삼 달팽이</a>
                        <div class="last-in-menu-wrap">
                          <ul class="last-in-menu">
                            <li>
                              <a href="javascript:void(0)"
                                >2Step 홍삼 달팽이 발효 마스크</a
                              >
                            </li>
                            <li>
                              <a href="/product-detail?type=snail"
                                >홍삼 달팽이 골드 세럼</a
                              >
                            </li>
                            <li>
                              <a href="/product-detail?type=snail"
                                >홍삼 달팽이 크림</a
                              >
                            </li>
                          </ul>
                        </div>
                      </li> -->
                      <li>
                        <a href="javascript:void(0)">버섯 발효 콤부차</a>
                        <div class="last-in-menu-wrap">
                          <ul class="last-in-menu">
                            <li>
                              <a href="javascript:void(0)"
                                >버섯 발효 콤부차 스킨 토너</a
                              >
                            </li>
                            <li>
                              <a href="javascript:void(0)"
                                >버섯 발효 콤부차 크림</a
                              >
                            </li>
                            <li>
                              <a href="javascript:void(0)"
                                >버섯 발효 콤부차 에어쿠션</a
                              >
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)"
                  >바디케어
                  <div class="sub-in-menu-wrap">
                    <ul class="sub-in-menu">
                      <li>
                        <a href="javascript:void(0)">장미</a>
                        <div class="last-in-menu-wrap">
                          <ul class="last-in-menu">
                            <li>
                              <a href="/product-detail?type=handCream"
                                >핸드크림 & 립밤</a
                              >
                            </li>
                            <li>
                              <a href="javascript:void(0)">바디로션</a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">향수</a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li class="nav-item">
          <a href="http://modamkorea.com/" target="_blank">E - Shop</a>
        </li>
        <li class="nav-item">
          <a href="/contact">Contact</a>
        </li>
      </ul>
    </nav>
    <!-- // WEB nav -->

    <!-- mobile nav -->
    <div class="mobile-nav-btn" (click)="mobileNavToggle()">
      <img src="assets/imgs/mobile_nav_icon.png" alt="" />
    </div>

    <nav class="mobile-nav new-version">
      <div class="tab-btns">
        <ul>
          <li class="tab active">
            <!-- <a href="/about">About 모담</a> -->
            <a href="javascript:void(0)">About 모담</a>
          </li>
          <li class="tab">
            <!-- <a href="/product">제품소개</a> -->
            <a href="javascript:void(0)">제품소개</a>
          </li>
          <li class="tab">
            <a href="http://modamkorea.com/" target="_blank">E - Shop</a>
          </li>
          <li class="tab">
            <a href="/contact">Contact</a>
          </li>
          <li class="tab">
            <a href="javascript:void(0)">Language</a>
          </li>
        </ul>
      </div>
      <!-- // .tab-btns -->

      <div class="tab-cont">
        <ul>
          <li class="visible">
            <div class="cont-wrap">
              <a href="/about">About 모담</a>
              <a href="/lab">모담 연구소</a>
              <a href="/global">글로벌 모담</a>
            </div>
          </li>
          <li>
            <div class="cont-wrap toggle-cont">
              <!-- 헤어케어 -->
              <mat-accordion class="example-headers-align hair-care" multi>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>헤어케어</mat-panel-title>
                  </mat-expansion-panel-header>
                  <a href="/product-detail?type=shampoo">고체 샴푸바</a>
                  <a href="javascript:void(0)">린스바</a>
                  <a href="/product-detail?type=shampooLiquid">샴푸액</a>
                  <a href="javascript:void(0)">헤어 에센스</a>
                  <a href="javascript:void(0)">헤어 미스트</a>
                </mat-expansion-panel>
              </mat-accordion>

              <!-- 페이셜케어 -->
              <mat-accordion class="example-headers-align facial-care" multi>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>페이셜케어</mat-panel-title>
                  </mat-expansion-panel-header>
                  <h3>아슈와간다</h3>
                  <a href="/product-detail?type=ash"
                    >2Step 아슈와간다 발효 마스크</a
                  >
                  <a href="/product-detail?type=eyePatch"
                    >3Step 아슈와간다 발효 마스크(아이패치)</a
                  >
                  <a href="/product-detail?type=cica" class="mb30"
                    >3Step 아슈와간다 발효 마스크(시카)</a
                  >
                  <h3>장미</h3>
                  <a href="/product-detail?type=rose"
                    >2Step 로즈 앱솔루트 마스크</a
                  >
                  <a href="javascript:void(0)" class="mb30">로즈 미스트</a>
                  <h3>홍삼 달팽이</h3>
                  <a href="javascript:void(0)">2Step 홍삼 달팽이 발효 마스크</a>
                  <a href="/product-detail?type=snail">홍삼 달팽이 골드 세럼</a>
                  <a href="/product-detail?type=snail" class="mb30"
                    >홍삼 달팽이 크림</a
                  >
                  <h3>버섯 발효 콤부차</h3>
                  <a href="javascript:void(0)">버섯 발효 콤부차 스킨 토너</a>
                  <a href="javascript:void(0)">버섯 발효 콤부차 크림</a>
                  <a href="javascript:void(0)" class="mb30"
                    >버섯 발효 콤부차 에어쿠션</a
                  >
                </mat-expansion-panel>
              </mat-accordion>

              <!-- 바디케어 -->
              <mat-accordion class="example-headers-align facial-care" multi>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>바디케어</mat-panel-title>
                  </mat-expansion-panel-header>
                  <h3>장미</h3>
                  <a href="/product-detail?type=handCream">핸드크림 & 립밤</a>
                  <a href="javascript:void(0)">바디로션</a>
                  <a href="javascript:void(0)" class="mb30">향수</a>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </li>
          <!-- index를 위한 공백태그 -->
          <li></li>
          <!-- index를 위한 공백태그 -->
          <li></li>
          <li>
            <div class="cont-wrap">
              <a href="javascript:void(0)">English</a>
              <a href="javascript:void(0)">日本語</a>
              <a href="javascript:void(0)">中文</a>
              <a href="javascript:void(0)">DEUTSCH</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>

    <nav class="mobile-nav" style="display: none">
      <!-- About 모담 -->
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title><a href="/about">About 모담</a></mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            <a href="/about">About 모담</a>
          </p>
          <p>
            <a href="/lab">모담 연구소</a>
          </p>
          <p>
            <a href="/global">글로벌 모담</a>
          </p>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- 제품소개 -->
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <a href="/product">제품소개</a>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            <a href="/product-detail?type=shampoo"
              >천연 발효 숙성 모담 고체 샴푸바</a
            >
          </p>
          <p>
            <a href="/product-detail?type=ash"
              >모담 2Step 아슈와간다 발효 마스크</a
            >
          </p>
          <p>
            <a href="/product-detail?type=rose"
              >모담 2Step 로즈 앱솔루트 마스크</a
            >
          </p>
          <p>
            <a href="/product-detail?type=cica"
              >모담 3Step 아슈와간다 발효 마스크 ( 시카 )</a
            >
          </p>
          <p>
            <a href="/product-detail?type=eyePatch"
              >모담 3Step 아슈와간다 발효 마스크 ( 아이패치 )</a
            >
          </p>
          <p>
            <a href="/product-detail?type=shampooLiquid">모담 샴푸액</a>
          </p>
          <p>
            <a href="/product-detail?type=handCream"
              >2in1 로즈퍼퓸 워터드롭 핸드크림&립밤</a
            >
          </p>
          <p>
            <a href="/product-detail?type=snail">홍삼달팽이 골드세럼 & 크림</a>
          </p>
        </mat-expansion-panel>
      </mat-accordion>
      <!-- E - Shop -->
      <a href="http://modamkorea.com/" class="category-btn">E - Shop</a>
      <!-- Contact -->
      <a href="javascript:void(0)" class="category-btn" routerLink="/contact"
        >Contact</a
      >
      <!-- Language -->
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>Language</mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            <a href="javascript:void(0)">ENGLISH</a>
          </p>
          <p>
            <a href="javascript:void(0)">日本語</a>
          </p>
          <p>
            <a href="javascript:void(0)">中文</a>
          </p>
          <p>
            <a href="javascript:void(0)">DEUTSCH</a>
          </p>
        </mat-expansion-panel>
      </mat-accordion>
    </nav>
    <!-- // mobile nav -->
  </div>
</header>
