import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'header-share',
  templateUrl: './header-share.component.html',
  styleUrls: ['./header-share.component.scss'],
})
export class HeaderShareComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    let body = document.querySelector<HTMLElement>('body');
    body.style.overflow = 'unset';
    this.mobileNavTabs();
  }

  // @HostListener('document:click', ['$event'])
  // removeOpenClass(event: MouseEvent) {
  //   let dropDownParent = document.querySelector<HTMLElement>('.language');
  //   event.target, 'open';
  //   let open = document.querySelector<HTMLElement>('.open');
  //   if (open === dropDownParent) {
  //     dropDownParent.classList.remove('open');
  //   }
  // }

  // languageMenuToggle() {
  //   let toggle = document.querySelector<HTMLElement>('.language');
  //   toggle.classList.toggle('open');
  // }

  mobileNavToggle() {
    let mobileNav = document.querySelector<HTMLElement>('.mobile-nav');
    let show = document.querySelector<HTMLElement>('.show');

    mobileNav.classList.toggle('show');
    let body = document.querySelector<HTMLElement>('body');

    if (mobileNav == show) {
      body.style.overflow = 'unset';
    } else {
      body.style.overflow = 'hidden';
    }
  }

  mobileNavTabs() {
    let tab = $('.tab');
    let tabCont = $('.tab-cont > ul > li');

    tab.on('click', function () {
      let tabIndex = $(this).index();
      let contIndex = tabCont.eq(tabIndex);

      if (tabIndex >= 2 && tabIndex <= 3) {
        setTimeout(() => {
          return false;
        }, 100);
      } else {
        tab.removeClass('active');
        $(this).addClass('active');
        tabCont.removeClass('visible');
        contIndex.addClass('visible');
      }
    });
  }

  // goDetail(type) {
  //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
  //     this.router.navigate(['/product-detail'], {
  //       queryParams: {
  //         type,
  //       },
  //     })
  //   );
  // }
}
