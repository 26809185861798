import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'footer-share',
  templateUrl: './footer-share.component.html',
  styleUrls: ['./footer-share.component.scss'],
})
export class FooterShareComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  year: number = new Date().getFullYear();
}
