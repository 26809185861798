<!-- 푸터 -->
<footer>
  <div class="partner-wrap">
    <div class="container">
      <p class="sub-logo"><img src="assets/imgs/sub_logo.png" alt="" /></p>
      <ul>
        <li><img src="assets/imgs/partner_icon01.png" alt="" /></li>
        <li><img src="assets/imgs/partner_icon02.png" alt="" /></li>
        <li><img src="assets/imgs/partner_icon03.png" alt="" /></li>
        <li><img src="assets/imgs/partner_icon04.png" alt="" /></li>
        <li><img src="assets/imgs/partner_icon05.png" alt="" /></li>
        <li><img src="assets/imgs/partner_icon06.png" alt="" /></li>
      </ul>
    </div>
  </div>
  <div class="footer">
    <div class="container">
      <div class="footer-logo">
        <img src="assets/imgs/footer_logo.png" alt="" />
      </div>
      <div class="footer-text">© {{ year }} MODAM Global Nature Co. Ltd</div>
    </div>
  </div>
</footer>
