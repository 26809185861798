<header-share></header-share>
<section class="global-wrap">
  <top-title
    title="Global MODAM"
    sub="이미 세계로 진출해 있는 모담코리아 입니다"
    secondLine="모담의 제품을 경험하신 분들은 지속적인 사용을 원하십니다"
  ></top-title>

  <article>
    <div class="container">
      <!-- web 지도 -->
      <div class="img-map">
        <img src="assets/imgs/basic_map.png" alt="" />
        <div class="country-map">
          <img src="assets/imgs/korea_map.png" alt="" class="active" />
          <img src="assets/imgs/usa_map.png" alt="" />
          <img src="assets/imgs/russia_map.png" alt="" />
          <img src="assets/imgs/singapore_map.png" alt="" />
          <img src="assets/imgs/myanmar_map.png" alt="" />
          <img src="assets/imgs/japan_map.png" alt="" />
          <img src="assets/imgs/germany_map.png" alt="" />
          <img src="assets/imgs/china_map.png" alt="" />
          <img src="assets/imgs/thailand_map.png" alt="" />
          <img src="assets/imgs/vietnam_map.png" alt="" />
        </div>
      </div>

      <!-- mobile 지도 -->
      <div class="img-map mobile">
        <div class="country-map mobile">
          <div class="korea-map active"></div>
          <div class="usa-map"></div>
          <div class="russia-map"></div>
          <div class="singapore-map"></div>
          <div class="myanmar-map"></div>
          <div class="japan-map"></div>
          <div class="germany-map"></div>
          <div class="china-map"></div>
          <div class="thailand-map"></div>
          <div class="vietnam-map"></div>
        </div>
      </div>

      <div class="btn-wrap">
        <ul class="btns">
          <li><a href="javascript:void(0)" class="active">Korea</a></li>
          <li><a href="javascript:void(0)">U.S.A</a></li>
          <li><a href="javascript:void(0)">Russia</a></li>
          <li><a href="javascript:void(0)">Singapore</a></li>
          <li><a href="javascript:void(0)">Myanmar</a></li>
          <li><a href="javascript:void(0)">Japan</a></li>
          <li><a href="javascript:void(0)">Germany</a></li>
          <li><a href="javascript:void(0)">China</a></li>
          <li><a href="javascript:void(0)">Thailand</a></li>
          <li><a href="javascript:void(0)">Vietnam</a></li>
        </ul>
      </div>
    </div>
  </article>
</section>
<footer-share></footer-share>
