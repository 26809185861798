import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterShareComponent } from './footer-share/footer-share.component';
import { HeaderShareComponent } from './header-share/header-share.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { TopTitleComponent } from './top-title/top-title.component';
import { RouterModule } from '@angular/router';
import { SafePipe } from './pipe/safe.pipe';

@NgModule({
  declarations: [
    FooterShareComponent,
    HeaderShareComponent,
    TopTitleComponent,
    SafePipe,
  ],
  imports: [CommonModule, MatExpansionModule, RouterModule],
  exports: [
    FooterShareComponent,
    HeaderShareComponent,
    TopTitleComponent,
    SafePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ShareModule {}
