import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-global',
  templateUrl: './global.component.html',
  styleUrls: ['./global.component.scss'],
})
export class GlobalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    this.mapSelected();
  }

  mapSelected() {
    let mapImg = $('.country-map > img');
    let mapMobileImg = $('.country-map.mobile > div');
    let btns = $('.btns > li');

    btns.on('click', function () {
      btns.children('a').removeClass('active');
      $(this).children('a').addClass('active');

      mapImg.removeClass('active');
      mapMobileImg.removeClass('active');
      const i = $(this).index();
      mapImg[i].classList.add('active');
      mapMobileImg[i].classList.add('active');
    });
  }
}
