<header-share></header-share>
<section class="product-wrap">
  <top-title
    title="MODAM Product"
    sub="자연의 능력을 옮겨온 모담의 제품들을 만나보세요"
  ></top-title>

  <article>
    <div class="container">
      <div class="product-item">
        <div class="img-wrap">
          <img
            src="assets/imgs/product_src_img01.jpg"
            alt="천연 발효 숙성 모담 고체 샴푸바 이미지"
            class="default"
          />
          <img
            src="assets/imgs/product_src_hover_img01.jpg"
            alt=""
            class="on"
          />
        </div>
        <div class="text-area">
          <h3>천연 발효 숙성 모담 고체 샴푸바</h3>
          <p class="en-name">Natural Fermented Ripen MODAM Solid Shampoo Bar</p>
          <p class="simple-info">
            <span class="price">49,000원</span>
            <span class="weight">90g</span>
          </p>
          <p class="ifno-text-title">상세정보</p>
          <p class="info-descript">
            모담의 고체 샴푸바는 인공 화학 성분이 첨가없이 100% 천연 성분만으로
            만들어졌습니다. 샴푸 바에는 욕창이 치료될 정도로 항염과 항산화
            효과가 좋은 대자호를 비롯하여 어성초, 녹차, 회향, 솔잎, 박하, 감잎,
            흑삼, 흑마늘, 로즈마리, 모링가 등 20종의 탈모를 막아주는 허브 성분이
            포함되어 있습니다. 모담이 개발한 발효 숙성 기술은 천연 재료의 유해한
            성분을 제거하고 영양 성분 흡수율을 극대화합니다. 모담 샴푸바로 처음
            머리를 감으면 두피의 염증이 줄어들고 모발이 두꺼워지고, 지속적으로
            사용하면 모발이 덜 빠지는 것을 볼 수 있습니다. 두피 건조 및 비듬으로
            인한 가려움증과 같은 여러 가지 두피 문제 개선에도 좋습니다. 이미
            권위있는 인증기관인 KTR, KCL, 대한피부과학연구소에서 검증이 완료된
            제품입니다.
          </p>
          <a href="/product-detail?type=shampoo" class="more-btn">
            더 알아보기
          </a>
          <div class="video-wrap">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/Xq5XAp6yR_M?controls=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <div class="product-item">
        <div class="img-wrap">
          <img
            src="assets/imgs/product_src_img02.jpg"
            alt="모담 2Step 아슈와간다 발효 마스크 이미지"
            class="default"
          />
          <img
            src="assets/imgs/product_src_hover_img02.jpg"
            alt=""
            class="on"
          />
        </div>
        <div class="text-area">
          <h3>모담 2Step 아슈와간다 발효 마스크</h3>
          <p class="en-name">MODAM 2Step Ashwagandha Fermented Mask</p>
          <p class="simple-info">
            <span class="price">8,000원</span>
            <span class="weight">27ml</span>
          </p>
          <p class="ifno-text-title">상세정보</p>
          <p class="info-descript">
            <span class="br">[ Step1 모담 모링가 비타민C 8.7앰플 ]</span>
            마스크팩 사용 전에 바르는 고농축 임팩트 앰플로 비타민, 광물질,
            아미노산이 풍부한 모링가오일과, 천연 비타민C 8.7%(영국산 비타민C)의
            작용으로 피부 탄력과 보습은 물론 피부 각질을 부드럽게 정리해주고,
            충분한 수분을 공급해주어 마스크팩의 흡수를 돕고 효과를 높여주는
            시너지 앰플 입니다.
            <br />
            <br />
            <span class="br">[ Step2 모담 아슈와간다 발효 마스크 ]</span>
            앰플(25ml) 한병의 고농축 모담 아슈와간다를 담았습니다. 모담
            아슈와간다는 수천년 역사의 Ayurveda의 피부과학과 현대의 섬세한
            생명과학을 토대로 건강하고 아름다운 피부의 근원과 원리를 연구하고
            발전시키고 있습니다. 인도에서 직수입한 원료인 아슈와간다는 “인도의
            산삼(사포닌 함량 인삼의 50배)” 라 불리며 한국 화장품에서는 보기 드문
            원료를 사용하였습니다. 또한 불로장생의 나무, 기적의 나무라 일컫는
            모링가 오일과 추출물을 함유하고 있으며 모담이 숙성/발효한
            진액(스테비아, 모링가, 그라비올라, 녹차, 당귀, 육계나무껍질, 감초,
            편백잎, 쑥잎, 어성초)을 고농도로 처방한 미백과 주름을 개선하는 2중
            기능성 시트 마스크입니다. 칼슘과 마그네슘, 철분, 칼륨 등 신진대사를
            촉진하고 피부에 중요한 미량원소(micro elements)가 많은 자작나무수액,
            진귀한 물로 만든 고급 마스크팩으로 피부에 순하고 부드러운 실크를
            함유한 고급 큐프라 시트 사용으로 들뜸없이 밀착력이 우수한 풍부한
            영양과 집중적인 수분공급 효과를 부여합니다.
          </p>
          <a href="/product-detail?type=ash" class="more-btn"> 더 알아보기 </a>
        </div>
      </div>
      <div class="product-item">
        <div class="img-wrap">
          <img
            src="assets/imgs/product_src_img03.jpg"
            alt="모담 2Step 로즈 앱솔루트 마스크 이미지"
            class="default"
          />
          <img
            src="assets/imgs/product_src_hover_img03.jpg"
            alt=""
            class="on"
          />
        </div>
        <div class="text-area">
          <h3>모담 2Step 로즈 앱솔루트 마스크</h3>
          <p class="en-name">MODAM 2Step Rose Absolute Mask</p>
          <p class="simple-info">
            <span class="price">8,500원</span>
            <span class="weight">27ml</span>
          </p>
          <p class="ifno-text-title">상세정보</p>
          <p class="info-descript">
            <span class="br">[ Step1 모담 모링가 비타민C 8.7앰플 ]</span>
            마스크팩 사용 전에 바르는 고농축 임팩트 앰플로 비타민, 광물질,
            아미노산이 풍부한 모링가오일과, 천연 비타민C 8.7%(영국산 비타민C)의
            작용으로 피부 탄력과 보습은 물론 피부 각질을 부드럽게 정리해주고,
            충분한 수분을 공급해주어 마스크팩의 흡수를 돕고 효과를 높여주는
            시너지 앰플 입니다.
            <br />
            <br />
            <span class="br">[ Step2 모담 로즈 마스크 ]</span>
            불가리아에서 직접 수입한 고가의 장미로 만든 로즈 앱솔루트(에센셜
            오일), 로즈 워터, 로즈 힙씨드오일 등 상질의 원료만 담았습니다.
            피부의 진피까지 침투하는 로즈 앱솔루트 성분은 효과적인 피부개선에
            도움을 줍니다. 100% 천연 장미향으로 인공향은 전혀 첨가되지 않았으며
            큐프라 40 고급시트 사용으로 들뜸 없이 밀착력이 우수하고 풍부한
            영양과 집중적인 수분공급 효과를 부여합니다.
          </p>
          <a href="/product-detail?type=rose" class="more-btn"> 더 알아보기 </a>
          <div class="video-wrap">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/0k00mIwxIEI?controls=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <div class="product-item">
        <div class="img-wrap">
          <img
            src="assets/imgs/product_src_img04.jpg"
            alt="모담 3Step 아슈와간다 발효 마스크(시카)"
          />
        </div>
        <div class="text-area">
          <h3>모담 3Step 아슈와간다 발효 마스크(시카)</h3>
          <p class="en-name">MODAM 3Step Ashwagandha Fermented Mask(CICA)</p>
          <p class="simple-info">
            <span class="price">9,500원</span>
            <span class="weight">1.7ml + 27ml + 1.7ml</span>
          </p>
          <p class="ifno-text-title">상세정보</p>
          <p class="info-descript">
            <span class="br">[ STEP 1 모담 모링가 비타민C 10 앰플 ]</span>
            마스크팩 사용 전에 바르는 고농축 임팩트 앰플로 비타민, 광물질,
            아미노산이 풍부한 모링가 오일과, 천연 비타민C 10%(영국산 비타민C)의
            작용으로 피부 탄력과 보습은 물론 피부 각질을 부드럽게 정리해주고,
            충분한 수분을 공급해주어 마스크팩의 흡수를 돕고 효과를 높여주는
            시너지 앰플입니다.
            <br />
            <br />
            <span class="br">[ STEP 2 모담 아슈와간다 발효 마스크팩 ]</span>
            앰플(27ml) 한병의 고농축 모담 아슈와간다를 담았습니다. 모담
            아슈와간다는 수천년 역사의 Ayuraveda의 피부과학과 현대의 섬세한
            생명과학을 토대로 건강하고 아름다운 피부의 근원과 원리를 연구하고
            발전시키고 있습니다. "인도의 산삼" 신비로운 비법의 아슈와간다를
            함유하고 불로장생의 나무, 기적의 나무로 일컫는 모링가 오일과
            추출물을 함유하고 있으며 모담이 숙성/발효한 진액(스테비아, 모링가,
            그라비올라, 녹차, 당귀, 육계나무껍질, 감초, 편백잎, 쑥잎, 어성초)을
            고농도로 처방한 미백과 주름을 개선하는 2중 기능성 시트 마스크입니다.
            칼슘과 마그네슘, 철분, 칼륨 등 신진대사를 촉진하고 피부에 중요한
            미량원소(Micro Elements)가 많은 자작나무수액, 진귀한 물로 만든 고급
            마스크팩으로 피부에 순하고 부드러운 실크를 함유한 고급시트사용으로
            들뜸없이 밀착력이 우수한 풍부한 영양과 집중적인 수분공급 효과를
            부여합니다.
            <br />
            <br />
            <span class="br"
              >[ STEP 3 모담 마린콜라겐 시카 올 페이스 에센스 ]</span
            >
            깔끔하고 쫀쫀한 마무리감으로 민감한 눈가, 목 등 주름지기 쉬운 부분
            뿐만 아니라 얼굴 전체에 3종의 시카케어 및 마린에너지를 가득 담은
            고보습 시카케어 올 페이스 에센스입니다.
          </p>
          <a href="/product-detail?type=cica" class="more-btn"> 더 알아보기 </a>
        </div>
      </div>
      <div class="product-item">
        <div class="img-wrap">
          <img
            src="assets/imgs/product_src_img05.jpg"
            alt="모담 3Step 아슈와간다 발효 마스크(아이패치)"
          />
        </div>
        <div class="text-area">
          <h3>모담 3Step 아슈와간다 발효 마스크(아이패치)</h3>
          <p class="en-name">
            MODAM 2Step Ashwagandha Fermented Mask(Eye Patch)
          </p>
          <p class="simple-info">
            <span class="price">9,500원</span>
            <span class="weight">25ml + 1.7ml</span>
          </p>
          <p class="ifno-text-title">상세정보</p>
          <p class="info-descript">
            <span class="br">[ Step1 모담 모링가 비타민C 10앰플 ]</span>
            마스크팩 사용 전에 바르는 고농축 임팩트 앰플로 비타민, 광물질,
            아미노산이 풍부한 모링가오일과, 천연 비타민C 10%(영국산 비타민C)의
            작용으로 피부 탄력과 보습은 물론 피부 각질을 부드럽게 정리해주고,
            충분한 수분을 공급해주어 마스크팩의 흡수를 돕고 효과를 높여주는
            시너지 앰플 입니다.
            <br />
            <br />
            <span class="br">[ Step2 콜라겐 아이패치 ]</span>
            부착시 자연스럽게 피부에 스며드는 콜라겐 아이패치로 피부 탄력 및
            주름 개선에 도움이 됩니다.
            <br />
            <br />
            <span class="br">[ Step3 모담 아슈와간다 발효 마스크 ]</span>
            앰플(27ml) 한병의 고농축 모담 아슈와간다를 담았습니다. 모담
            아슈와간다는 수천년 역사의 Ayurveda의 피부과학과 현대의 섬세한
            생명과학을 토대로 건강하고 아름다운 피부의 근원과 원리를 연구하고
            발전시키고 있습니다. “인도의 산삼(사포닌 함량 인삼의 50배)” 신비로운
            비법의 아슈와간다를 함유하고 불로장생의 나무, 기적의 나무라 일컫는
            모링가 오일과 추출물을 함유하고 있으며 모담이 숙성/발효한
            진액(스테비아, 모링가, 그라비올라, 녹차, 당귀, 육계나무껍질, 감초,
            편백잎, 쑥잎, 어성초)을 고농도로 처방한 미백과 주름을 개선하는 2중
            기능성 시트 마스크입니다. 칼슘과 마그네슘, 철분, 칼륨 등 신진대사를
            촉진하고 피부에 중요한 미량원소(micro elements)가 많은 자작나무수액,
            진귀한 물로 만든 고급 마스크팩으로 피부에 순하고 부드러운 실크를
            함유한 고급시트사용으로 들뜸없이 밀착력이 우수한 풍부한 영양과
            집중적인 수분공급 효과를 부여합니다.
          </p>
          <a href="/product-detail?type=eyePatch" class="more-btn">
            더 알아보기
          </a>
        </div>
      </div>
      <div class="product-item">
        <div class="img-wrap">
          <img src="assets/imgs/product_src_img06.jpg" alt="모담 샴푸액" />
        </div>
        <div class="text-area">
          <h3>모담 샴푸액</h3>
          <p class="en-name">MODAM Shampoo(Liquid)</p>
          <p class="simple-info">
            <span class="price">49,000원</span>
            <span class="weight">300ml</span>
          </p>
          <p class="ifno-text-title">상세정보</p>
          <p class="info-descript">
            모담 샴푸액은 탈모 개선 및 방지 뿐 아니라 모발 탄력과 머리카락을
            더욱 건강하게 해줍니다. 또한 비듬, 가려움증 및 두피 트러블을 개선해
            주는데 좋습니다. 모담 샴푸액은 두피에 집중적으로 영양을 공급하여
            두피의 자극을 주지 않고 탈모를 예방하는 효과적인 허브 성분을
            함유하고 있습니다. 이런 허브의 발효 숙성 기술로 허브의 독성을
            제거하고 영양 입자를 인체에서 흡수하기 좋게 분해하여 흡수력을 높여서
            두피 염증을 감소시킵니다. 탈모 예방, 양모 의약외품 승인, 미국 FDA
            승인, 살충제 및 중금속 시험에 대한 식품 의약품 안전청의 승인을
            받았습니다.
          </p>
          <a href="/product-detail?type=shampooLiquid" class="more-btn">
            더 알아보기
          </a>
        </div>
      </div>
      <div class="product-item">
        <div class="img-wrap">
          <img
            src="assets/imgs/product_src_img07.jpg"
            alt="2in1 로즈퍼퓸 워터드롭 핸드크림&립밤"
          />
        </div>
        <div class="text-area">
          <h3>2in1 로즈퍼퓸 워터드롭 핸드크림&립밤</h3>
          <p class="en-name">
            2in1 Rose Perfume Water drop Hand Cream & Lip Balm
          </p>
          <p class="simple-info">
            <span class="price">15,000원</span>
            <span class="weight">30g</span>
          </p>
          <p class="ifno-text-title">상세정보</p>
          <p class="info-descript">
            2in1 타입으로 핸드크림과 립밤을 동시에 사용할 수 있습니다.
            크림타입이 아닌 워터드롭 타입이기 때문에 산뜻하게 사용할 수
            있습니다. 고급스러운 장미향으로 기분까지 향긋하게 만들어 줍니다.
          </p>
          <a href="/product-detail?type=handCream" class="more-btn">
            더 알아보기
          </a>
        </div>
      </div>
      <!-- <div class="product-item">
        <div class="img-wrap">
          <img
            src="assets/imgs/product_src_img08.jpg"
            alt="홍삼달팽이 골드세럼 & 크림"
          />
        </div>
        <div class="text-area">
          <h3>홍삼달팽이 골드세럼 & 크림</h3>
          <p class="en-name">Red Ginseng Snail Gold Serum & Cream</p>
          <p class="simple-info">
            <span class="price">49,000원</span>
            <span class="weight">300ml</span>
          </p>
          <p class="ifno-text-title">상세정보</p>
          <p class="info-descript">
            <span class="br">[ 홍삼달팽이 골드세럼 ]</span>
            홍삼을 먹여 키운 달팽이로부터 유래한 달팽이 점액을 함유하여 상처치유
            및 피부재생 효과, 피부 자극 완화 효과가 뛰어납니다. 또한 불로장생의
            나무, 기적의 나무라 일컫는 모링가 추출물을 함유하고 있으며 인도의
            인삼 이라 불리는 아슈와간다 추출물과 금가루를 함유하여 독소, 노폐물
            배출 및 피부톤을 개선시켜 줍니다. 산뽕나무줄기추출물, 마치현추출물,
            녹차추출물 등 피부 컨디셔닝에 효과적인 성분들이 작용하여 피부 보습
            및 진정효과를 부여하고 미백과 주름을 개선하는 2중기능성 세럼입니다.
            <br />
            <br />
            <span class="br">[ 홍삼달팽이 크림 ]</span>
            특허 성분인 홍삼을 먹여 키운 달팽이로부터 유래한 달팽이 점액
            여과물이 30%이상 들어가 있어 각종 외부 환경으로 인해 손상된 피부를
            밤시간동안 케어 해주어 피부 장벽 강화에 도움을 주며 인도의 인삼이라
            불리는 아슈와간다 추출물을 함유하여 항산화 작용, 항균, 미백 효과를
            볼 수 있고, 유용 성분들이 피부 속부터 탄탄하게 채우며 피부탄력에도
            효과를 주는 고수분 고영양 나이트크림입니다.
          </p>
          <a href="/product-detail?type=snail" class="more-btn">
            더 알아보기
          </a>
        </div>
      </div> -->
    </div>
  </article>
</section>
<footer-share></footer-share>
