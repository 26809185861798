import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import * as Isotope from '../../../assets/js/isotope.pkgd.min.js';
import * as Fancybox from '../../../assets/js/jquery.fancybox.min.js';

@Component({
  selector: 'app-lab',
  templateUrl: './lab.component.html',
  styleUrls: ['./lab.component.scss'],
})
export class LabComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    this.itemSelect();
    this.isotope();
  }

  itemSelect() {
    $('.tab-nav > li > a').on('click', function () {
      $('.tab-nav > li > a').removeClass('active');
      $(this).addClass('active');
    });
  }

  isotope() {
    let list = document.querySelector<HTMLElement>('.item-list');
    let iso = new Isotope(list, {
      // options
      itemSelector: '.lab-item',
      layoutMode: 'fitRows',
    });

    $('.filter-button-group > li > a').on('click', function () {
      var filterValue = $(this).parent().attr('data-filter');
      // use filter function if value matches
      iso.arrange({ filter: filterValue });
    });
  }
}
