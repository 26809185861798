import { Component, OnInit } from '@angular/core';
import SwiperCore, {
  Pagination,
  Autoplay,
  Swiper,
  Navigation,
} from 'swiper/core';
import * as $ from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { data } from 'jquery';

SwiperCore.use([Pagination, Autoplay, Navigation]);

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnInit {
  items = [];

  shampoo: any = [
    {
      breadcrumb: '천연 발효 숙성 모담 고체 샴푸바',
      mainImgSrc: 'assets/imgs/thumb_01_img01.jpg',
      mainImgAlt: '천연 발효 숙성 모담 고체 샴푸바 이미지',
      subImgs: [
        { subImg: 'assets/imgs/thumb_01_img01.jpg' },
        { subImg: 'assets/imgs/thumb_01_img02.jpg' },
        { subImg: 'assets/imgs/thumb_01_img03.jpg' },
        { subImg: 'assets/imgs/thumb_01_img04.jpg' },
        { subImg: 'assets/imgs/thumb_01_img05.jpg' },
        { subImg: 'assets/imgs/thumb_01_img06.jpg' },
        { subImg: 'assets/imgs/thumb_01_img07.jpg' },
      ],
      koTitle: '천연 발효 숙성 모담 고체 샴푸바',
      enTitle: 'Natural Fermented Ripen MODAM Solid Shampoo Bar',
      // price: '49,000',
      weight: '90g',
      descript:
        '모담의 고체 샴푸바는 인공 화학 성분이 첨가없이 100% 천연 성분만으로만들어졌습니다. 샴푸바에는 욕창이 치료될 정도로 항염과 항산화 효과가 좋은 대자호를 비롯하여 어성초, 녹차, 회향, 솔잎, 박하, 감잎, 흑삼, 흑마늘, 로즈마리, 모링가 등 20종의 탈모를 막아주는 허브 성분이 포함되어 있습니다. 모담이 개발한 발효 숙성 기술은 천연 재료의 유해한 성분을 제거하고 영양 성분 흡수율을 극대화합니다. 모담 샴푸바로 처음 머리를 감으면 두피의 염증이 줄어들고 모발이 두꺼워지고, 지속적으로 사용하면 모발이 덜 빠지는 것을 볼 수 있습니다. 두피 건조 및 비듬으로 인한 가려움증과 같은 여러 가지 두피 문제 개선에도 좋습니다. 이미 권위있는 인증기관인 KTR, KCL, 대한피부과학연구소에서 검증이 완료된 제품입니다.',
      visible: true,
      youtubeSrc: 'https://www.youtube.com/embed/Xq5XAp6yR_M?controls=0',
      ingreds: [
        {
          ingredIconOff: 'assets/imgs/blackgarlic_icon.png',
          ingredIconOn: 'assets/imgs/blackgarlic_on_icon.png',
          ingredName: '흑마늘 추출물',
          ingredDesc: '항산화 효과',
        },
        {
          ingredIconOff: 'assets/imgs/moringa_icon.png',
          ingredIconOn: 'assets/imgs/moringa_on_icon.png',
          ingredName: '모링가 추출물',
          ingredDesc: '콜라겐 섬유재생을 도움\n주름과 피부 트러블개선',
        },
        {
          ingredIconOff: 'assets/imgs/blackginseng_icon.png',
          ingredIconOn: 'assets/imgs/blackginseng_on_icon.png',
          ingredName: '흑삼 추출물',
          ingredDesc: '기미, 주근깨 등 색소 침착 예방\n피부미백에 도움',
        },
        {
          ingredIconOff: 'assets/imgs/daejaho_icon.png',
          ingredIconOn: 'assets/imgs/daejaho_on_icon.png',
          ingredName: '대자호',
          ingredDesc: '항염 및 항산화 효과',
        },
        {
          ingredIconOff: 'assets/imgs/greentea_icon.png',
          ingredIconOn: 'assets/imgs/greentea_on_icon.png',
          ingredName: '녹차 추출물',
          ingredDesc: '기미, 주근깨 등 색소 침착 예방\n피부미백에 도움',
        },
        {
          ingredIconOff: 'assets/imgs/rosemary_icon.png',
          ingredIconOn: 'assets/imgs/rosemary_on_icon.png',
          ingredName: '로즈마리잎 추출물',
          ingredDesc: '두피개선, 모발성장촉진\n비듬 및 탈모방지에 탁월',
        },
        {
          ingredIconOff: 'assets/imgs/coconut_icon.png',
          ingredIconOn: 'assets/imgs/coconut_on_icon.png',
          ingredName: '코코넛 오일',
          ingredDesc: '손상된 피부 복구 및 복원\n피부 장벽 유지',
        },
        {
          ingredIconOff: 'assets/imgs/olive_icon.png',
          ingredIconOn: 'assets/imgs/olive_on_icon.png',
          ingredName: '올리브 오일',
          ingredDesc: '부드러운 피부결 관리 및\n영양공급',
        },
        {
          ingredIconOff: 'assets/imgs/camellia_icon.png',
          ingredIconOn: 'assets/imgs/camellia_on_icon.png',
          ingredName: '동백 오일',
          ingredDesc: '보습에 효과적이며\n모발에 윤기를 줌',
        },
      ],
    },
  ];

  ash: any = [
    {
      breadcrumb: '모담 2Step 아슈와간다 발효 마스크',
      mainImgSrc: 'assets/imgs/thumb_02_img01.jpg',
      mainImgAlt: '모담 2Step 아슈와간다 발효 마스크 이미지',
      subImgs: [
        { subImg: 'assets/imgs/thumb_02_img01.jpg' },
        { subImg: 'assets/imgs/thumb_02_img02.jpg' },
        { subImg: 'assets/imgs/thumb_02_img03.jpg' },
        { subImg: 'assets/imgs/thumb_02_img04.jpg' },
        { subImg: 'assets/imgs/thumb_02_img05.jpg' },
        { subImg: 'assets/imgs/thumb_02_img06.jpg' },
        { subImg: 'assets/imgs/thumb_02_img07.jpg' },
      ],
      koTitle: '모담 2Step 아슈와간다 발효 마스크',
      enTitle: 'MODAM 2Step Ashwagandha Fermented Mask',
      // price: '8,000',
      weight: '27ml',
      descript:
        '[ Step1 모담 모링가 비타민C 8.7앰플 ]\n마스크팩 사용 전에 바르는 고농축 임팩트 앰플로 비타민, 광물질, 아미노산이 풍부한 모링가오일과, 천연 비타민C 8.7%(영국산 비타민C)의 작용으로 피부 탄력과 보습은 물론 피부 각질을 부드럽게 정리해주고, 충분한 수분을 공급해주어 마스크팩의 흡수를 돕고 효과를 높여주는 시너지 앰플 입니다.\n\n[ Step2 모담 아슈와간다 발효 마스크 ]\n앰플(25ml) 한병의 고농축 모담 아슈와간다를 담았습니다. 모담 아슈와간다는 수천년 역사의 Ayurveda의 피부과학과 현대의 섬세한 생명과학을 토대로 건강하고 아름다운 피부의 근원과 원리를 연구하고 발전시키고 있습니다. 인도에서 직수입한 원료인 아슈와간다는 “인도의 산삼(사포닌 함량 인삼의 50배)” 라 불리며 한국 화장품에서는 보기 드문 원료를 사용하였습니다. 또한 불로장생의 나무, 기적의 나무라 일컫는 모링가 오일과 추출물을 함유하고 있으며 모담이 숙성/발효한 진액(스테비아, 모링가, 그라비올라, 녹차, 당귀, 육계나무껍질, 감초, 편백잎, 쑥잎, 어성초)을 고농도로 처방한 미백과 주름을 개선하는 2중 기능성 시트 마스크입니다. 칼슘과 마그네슘, 철분, 칼륨 등 신진대사를 촉진하고 피부에 중요한 미량원소(micro elements)가 많은 자작나무수액, 진귀한 물로 만든 고급 마스크팩으로 피부에 순하고 부드러운 실크를 함유한 고급 큐프라 시트 사용으로 들뜸없이 밀착력이 우수한 풍부한 영양과 집중적인 수분공급 효과를 부여합니다. ',
      visible: false,
      youtubeSrc: 'https://www.youtube.com/embed/Xq5XAp6yR_M?controls=0',
      ingreds: [
        {
          ingredIconOff: 'assets/imgs/ashwagandha_icon.png',
          ingredIconOn: 'assets/imgs/ashwagandha_on_icon.png',
          ingredName: '아슈와간다',
          ingredDesc: '콜라겐 생성 촉진\n및 노화방지에 도움',
        },
        {
          ingredIconOff: 'assets/imgs/birch_icon.png',
          ingredIconOn: 'assets/imgs/birch_on_icon.png',
          ingredName: '자작나무 수액',
          ingredDesc: '진정 및 보습효과,\n피부장벽 개선',
        },
        {
          ingredIconOff: 'assets/imgs/moringa_icon.png',
          ingredIconOn: 'assets/imgs/moringa_on_icon.png',
          ingredName: '모링가 추출물',
          ingredDesc: '콜라겐 섬유재생을 도움\n주름과 피부 트러블 개선',
        },
        {
          ingredIconOff: 'assets/imgs/vitaminc_icon.png',
          ingredIconOn: 'assets/imgs/vitaminc_on_icon.png',
          ingredName: '비타민 C',
          ingredDesc: '주름 예방 및 미백 효과',
        },
        {
          ingredIconOff: 'assets/imgs/greentea_icon.png',
          ingredIconOn: 'assets/imgs/greentea_on_icon.png',
          ingredName: '녹차 추출물',
          ingredDesc: '기미, 주근깨 등 색소 침착 예방\n피부미백에 도움',
        },
        {
          ingredIconOff: 'assets/imgs/cordata_icon.png',
          ingredIconOn: 'assets/imgs/cordata_on_icon.png',
          ingredName: '어성초',
          ingredDesc: '향균과 살균,\n소염작용 등에 탁월',
        },
      ],
    },
  ];

  rose: any = [
    {
      breadcrumb: '모담 2Step 로즈 앱솔루트 마스크',
      mainImgSrc: 'assets/imgs/thumb_03_img01.jpg',
      mainImgAlt: '모담 2Step 로즈 앱솔루트 마스크 이미지',
      subImgs: [
        { subImg: 'assets/imgs/thumb_03_img01.jpg' },
        { subImg: 'assets/imgs/thumb_03_img02.jpg' },
        { subImg: 'assets/imgs/thumb_03_img03.jpg' },
        { subImg: 'assets/imgs/thumb_03_img04.jpg' },
        { subImg: 'assets/imgs/thumb_03_img05.jpg' },
        { subImg: 'assets/imgs/thumb_03_img06.jpg' },
        { subImg: 'assets/imgs/thumb_03_img07.jpg' },
      ],
      koTitle: '모담 2Step 로즈 앱솔루트 마스크',
      enTitle: 'MODAM 2Step Rose Absolute Mask',
      // price: '8,500',
      weight: '27ml',
      descript:
        '[ Step1 모담 모링가 비타민C 8.7 앰플 ]\n마스크팩 사용 전에 바르는 고농축 임팩트 앰플로 비타민, 광물질, 아미노산이 풍부한 모링가오일과, 천연 비타민C 8.7%(영국산 비타민C)의 작용으로 피부 탄력과 보습은 물론 피부 각질을 부드럽게 정리해주고, 충분한 수분을 공급해주어 마스크팩의 흡수를 돕고 효과를 높여주는 시너지 앰플 입니다.\n\n[ Step2 모담 로즈 마스크 ]\n불가리아에서 직접 수입한 고가의 장미로 만든 로즈 앱솔루트(에센셜 오일), 로즈 워터, 로즈 힙씨드오일 등 상질의 원료만 담았습니다. 피부의 진피까지 침투하는 로즈 앱솔루트 성분은 효과적인 피부개선에 도움을 줍니다. 100% 천연 장미향으로 인공향은 전혀 첨가되지 않았으며 큐프라 40 고급시트 사용으로 들뜸 없이 밀착력이 우수하고 풍부한 영양과 집중적인 수분공급 효과를 부여합니다. ',
      visible: true,
      youtubeSrc: 'https://www.youtube.com/embed/0k00mIwxIEI?controls=0',
      ingreds: [
        {
          ingredIconOff: 'assets/imgs/rose_icon.png',
          ingredIconOn: 'assets/imgs/rose_on_icon.png',
          ingredName: '장미',
          ingredDesc: '수분 보충 및 ph밸런스 조절',
        },
        {
          ingredIconOff: 'assets/imgs/rosehip_icon.png',
          ingredIconOn: 'assets/imgs/rosehip_on_icon.png',
          ingredName: '로즈 힙씨드 오일',
          ingredDesc: '멜라닌 색소 침착 방지,\n피부 탄력 강화',
        },
        {
          ingredIconOff: 'assets/imgs/moringa_icon.png',
          ingredIconOn: 'assets/imgs/moringa_on_icon.png',
          ingredName: '모링가 추출물',
          ingredDesc: '콜라겐 섬유재생을 도움\n주름과 피부 트러블 개선',
        },
        {
          ingredIconOff: 'assets/imgs/vitaminc_icon.png',
          ingredIconOn: 'assets/imgs/vitaminc_on_icon.png',
          ingredName: '비타민 C',
          ingredDesc: '주름 예방 및 미백 효과',
        },
      ],
    },
  ];

  cica: any = [
    {
      breadcrumb: '모담 3Step 아슈와간다 발효 마스크(시카)',
      mainImgSrc: 'assets/imgs/thumb_04_img01.jpg',
      mainImgAlt: '모담 3Step 아슈와간다 발효 마스크(시카) 이미지',
      subImgs: [
        { subImg: 'assets/imgs/thumb_04_img01.jpg' },
        { subImg: 'assets/imgs/thumb_04_img02.jpg' },
      ],
      koTitle: '모담 3Step 아슈와간다 발효 마스크(시카)',
      enTitle: 'MODAM 3Step Ashwagandha Fermented Mask(CICA)',
      // price: '9,500',
      weight: '1.7ml + 27ml + 1.7ml',
      descript:
        '[ STEP 1 모담 모링가 비타민C 10 앰플 ]\n마스크팩 사용 전에 바르는 고농축 임팩트 앰플로 비타민, 광물질, 아미노산이 풍부한 모링가 오일과, 천연 비타민C 10%(영국산 비타민C)의 작용으로 피부 탄력과 보습은 물론 피부 각질을 부드럽게 정리해주고, 충분한 수분을 공급해주어 마스크팩의 흡수를 돕고 효과를 높여주는 시너지 앰플입니다.\n\n[ STEP 2 모담 아슈와간다 발효 마스크팩 ]\n앰플(27ml) 한병의 고농축 모담 아슈와간다를 담았습니다. 모담 아슈와간다는 수천년 역사의 Ayuraveda의 피부과학과 현대의 섬세한 생명과학을 토대로 건강하고 아름다운 피부의 근원과 원리를 연구하고 발전시키고 있습니다. "인도의 산삼" 신비로운 비법의 아슈와간다를 함유하고 불로장생의 나무, 기적의 나무로 일컫는 모링가 오일과 추출물을 함유하고 있으며 모담이 숙성/발효한 진액(스테비아, 모링가, 그라비올라, 녹차, 당귀, 육계나무껍질, 감초, 편백잎, 쑥잎, 어성초)을 고농도로 처방한 미백과 주름을 개선하는 2중 기능성 시트 마스크입니다. 칼슘과 마그네슘, 철분, 칼륨 등 신진대사를 촉진하고 피부에 중요한 미량원소(Micro Elements)가 많은 자작나무수액, 진귀한 물로 만든 고급 마스크팩으로 피부에 순하고 부드러운 실크를 함유한 고급시트사용으로 들뜸없이 밀착력이 우수한 풍부한 영양과 집중적인 수분공급 효과를 부여합니다.\n\n[ STEP 3 모담 마린콜라겐 시카 올 페이스 에센스 ]\n깔끔하고 쫀쫀한 마무리감으로 민감한 눈가, 목 등 주름지기 쉬운 부분 뿐만 아니라 얼굴 전체에 3종의 시카케어 및 마린에너지를 가득 담은 고보습 시카케어 올 페이스 에센스입니다.',
      visible: false,
      youtubeSrc: 'https://www.youtube.com/embed/0k00mIwxIEI?controls=0',
      ingreds: [
        {
          ingredIconOff: 'assets/imgs/ashwagandha_icon.png',
          ingredIconOn: 'assets/imgs/ashwagandha_on_icon.png',
          ingredName: '아슈와간다',
          ingredDesc: '콜라겐 생성 촉진\n및 노화방지에 도움',
        },
        {
          ingredIconOff: 'assets/imgs/birch_icon.png',
          ingredIconOn: 'assets/imgs/birch_on_icon.png',
          ingredName: '자작나무 수액',
          ingredDesc: '진정 및 보습효과,\n피부장벽 개선',
        },
        {
          ingredIconOff: 'assets/imgs/moringa_icon.png',
          ingredIconOn: 'assets/imgs/moringa_on_icon.png',
          ingredName: '모링가 추출물',
          ingredDesc: '콜라겐 섬유재생을 도움\n주름과 피부 트러블 개선',
        },
        {
          ingredIconOff: 'assets/imgs/vitaminc_icon.png',
          ingredIconOn: 'assets/imgs/vitaminc_on_icon.png',
          ingredName: '비타민 C',
          ingredDesc: '주름 예방 및 미백 효과',
        },
        {
          ingredIconOff: 'assets/imgs/greentea_icon.png',
          ingredIconOn: 'assets/imgs/greentea_on_icon.png',
          ingredName: '녹차 추출물',
          ingredDesc: '기미, 주근깨 등 색소 침착 예방\n피부미백에 도움',
        },
        {
          ingredIconOff: 'assets/imgs/cordata_icon.png',
          ingredIconOn: 'assets/imgs/cordata_on_icon.png',
          ingredName: '어성초',
          ingredDesc: '향균과 살균,\n소염작용 등에 탁월',
        },
      ],
    },
  ];

  eyePatch: any = [
    {
      breadcrumb: '모담 3Step 아슈와간다 발효 마스크(아이패치)',
      mainImgSrc: 'assets/imgs/thumb_05_img01.jpg',
      mainImgAlt: '모담 3Step 아슈와간다 발효 마스크(아이패치) 이미지',
      subImgs: [
        { subImg: 'assets/imgs/thumb_05_img01.jpg' },
        { subImg: 'assets/imgs/thumb_05_img02.jpg' },
        { subImg: 'assets/imgs/thumb_05_img03.jpg' },
      ],
      koTitle: '모담 3Step 아슈와간다 발효 마스크(아이패치)',
      enTitle: 'MODAM 3Step Ashwagandha Fermented Mask(Eye Patch)',
      // price: '9,500',
      weight: '25ml + 1.7ml',
      descript:
        '[ Step1 모담 모링가 비타민C 10앰플 ]\n마스크팩 사용 전에 바르는 고농축 임팩트 앰플로 비타민, 광물질, 아미노산이 풍부한 모링가오일과, 천연 비타민C 10%(영국산 비타민C)의 작용으로 피부 탄력과 보습은 물론 피부 각질을 부드럽게 정리해주고, 충분한 수분을 공급해주어 마스크팩의 흡수를 돕고 효과를 높여주는 시너지 앰플 입니다.\n\n[ Step2 콜라겐 아이패치 ]\n부착시 자연스럽게 피부에 스며드는 콜라겐 아이패치로 피부 탄력 및 주름 개선에 도움이 됩니다.\n\n[ Step3 모담 아슈와간다 발효 마스크 ]\n앰플(27ml) 한병의 고농축 모담 아슈와간다를 담았습니다. 모담 아슈와간다는 수천년 역사의 Ayurveda의 피부과학과 현대의 섬세한 생명과학을 토대로 건강하고 아름다운 피부의 근원과 원리를 연구하고 발전시키고 있습니다. “인도의 산삼(사포닌 함량 인삼의 50배)” 신비로운 비법의 아슈와간다를 함유하고 불로장생의 나무, 기적의 나무라 일컫는 모링가 오일과 추출물을 함유하고 있으며 모담이 숙성/발효한 진액(스테비아, 모링가, 그라비올라, 녹차, 당귀, 육계나무껍질, 감초, 편백잎, 쑥잎, 어성초)을 고농도로 처방한 미백과 주름을 개선하는 2중 기능성 시트 마스크입니다. 칼슘과 마그네슘, 철분, 칼륨 등 신진대사를 촉진하고 피부에 중요한 미량원소(micro elements)가 많은 자작나무수액, 진귀한 물로 만든 고급 마스크팩으로 피부에 순하고 부드러운 실크를 함유한 고급시트사용으로 들뜸없이 밀착력이 우수한 풍부한 영양과 집중적인 수분공급 효과를 부여합니다.',
      visible: false,
      youtubeSrc: 'https://www.youtube.com/embed/0k00mIwxIEI?controls=0',
      ingreds: [
        {
          ingredIconOff: 'assets/imgs/ashwagandha_icon.png',
          ingredIconOn: 'assets/imgs/ashwagandha_on_icon.png',
          ingredName: '아슈와간다',
          ingredDesc: '콜라겐 생성 촉진\n및 노화방지에 도움',
        },
        {
          ingredIconOff: 'assets/imgs/birch_icon.png',
          ingredIconOn: 'assets/imgs/birch_on_icon.png',
          ingredName: '자작나무 수액',
          ingredDesc: '진정 및 보습효과,\n피부장벽 개선',
        },
        {
          ingredIconOff: 'assets/imgs/moringa_icon.png',
          ingredIconOn: 'assets/imgs/moringa_on_icon.png',
          ingredName: '모링가 추출물',
          ingredDesc: '콜라겐 섬유재생을 도움\n주름과 피부 트러블 개선',
        },
        {
          ingredIconOff: 'assets/imgs/vitaminc_icon.png',
          ingredIconOn: 'assets/imgs/vitaminc_on_icon.png',
          ingredName: '비타민 C',
          ingredDesc: '주름 예방 및 미백 효과',
        },
        {
          ingredIconOff: 'assets/imgs/greentea_icon.png',
          ingredIconOn: 'assets/imgs/greentea_on_icon.png',
          ingredName: '녹차 추출물',
          ingredDesc: '기미, 주근깨 등 색소 침착 예방\n피부미백에 도움',
        },
        {
          ingredIconOff: 'assets/imgs/cordata_icon.png',
          ingredIconOn: 'assets/imgs/cordata_on_icon.png',
          ingredName: '어성초',
          ingredDesc: '향균과 살균,\n소염작용 등에 탁월',
        },
      ],
    },
  ];

  shampooLiquid: any = [
    {
      breadcrumb: '모담 샴푸액',
      mainImgSrc: 'assets/imgs/thumb_06_img01.jpg',
      mainImgAlt: '모담 샴푸액 이미지',
      koTitle: '모담 샴푸액',
      enTitle: 'MODAM Shampoo(Liquid)',
      // price: '49,000',
      weight: '300ml',
      descript:
        '모담 샴푸액은 탈모 개선 및 방지 뿐 아니라 모발 탄력과 머리카락을 더욱 건강하게 해줍니다. 또한 비듬, 가려움증 및 두피 트러블을 개선해 주는데 좋습니다. 모담 샴푸액은 두피에 집중적으로 영양을 공급하여 두피의 자극을 주지 않고 탈모를 예방하는 효과적인 허브 성분을 함유하고 있습니다. 이런 허브의 발효 숙성 기술로 허브의 독성을 제거하고 영양 입자를 인체에서 흡수하기 좋게 분해하여 흡수력을 높여서 두피 염증을 감소시킵니다. 탈모 예방, 양모 의약외품 승인, 미국 FDA 승인, 살충제 및 중금속 시험에 대한 식품 의약품 안전청의 승인을 받았습니다.',
      visible: false,
      youtubeSrc: 'https://www.youtube.com/embed/0k00mIwxIEI?controls=0',
      ingreds: [
        {
          ingredIconOff: 'assets/imgs/greentea_icon.png',
          ingredIconOn: 'assets/imgs/greentea_on_icon.png',
          ingredName: '녹차 추출물',
          ingredDesc: '기미 주근깨 등 색소침착 예방\n피부 미백에 도움',
        },
        {
          ingredIconOff: 'assets/imgs/hasuo_icon.png',
          ingredIconOn: 'assets/imgs/hasuo_on_icon.png',
          ingredName: '하수오',
          ingredDesc: '모발 윤기, 흰머리 방지\n탈모 방지 및 발모 촉진을 해줌',
        },
        {
          ingredIconOff: 'assets/imgs/blackbeans_icon.png',
          ingredIconOn: 'assets/imgs/blackbeans_on_icon.png',
          ingredName: '검정콩',
          ingredDesc: '피부에 탄력을 주며 주름형성\n지연, 상처 치료에 효과',
        },
        {
          ingredIconOff: 'assets/imgs/mugwort_icon.png',
          ingredIconOn: 'assets/imgs/mugwort_on_icon.png',
          ingredName: '쑥 추출물',
          ingredDesc: '노화 예방, 피부재생 촉진\n향균, 소독효과가 탁월',
        },
      ],
    },
  ];

  handCream: any = [
    {
      breadcrumb: '2in1 로즈퍼퓸 워터드롭 핸드크림&립밤',
      mainImgSrc: 'assets/imgs/thumb_07_img01.jpg',
      mainImgAlt: '2in1 로즈퍼퓸 워터드롭 핸드크림&립밤 이미지',
      koTitle: '2in1 로즈퍼퓸 워터드롭 핸드크림&립밤',
      enTitle: '2in1 Rose Perfume Water drop Hand Cream & Lip Balm',
      // price: '15,000',
      weight: '30g',
      descript:
        '2in1 타입으로 핸드크림과 립밤을 동시에 사용할 수 있습니다. 크림타입이 아닌\n워터드롭 타입이기 때문에 산뜻하게 사용할 수 있습니다. 고급스러운 장미향으로\n기분까지 향긋하게 만들어 줍니다.',
      visible: false,
      youtubeSrc: 'https://www.youtube.com/embed/0k00mIwxIEI?controls=0',
      ingreds: [
        {
          ingredIconOff: 'assets/imgs/olive_icon.png',
          ingredIconOn: 'assets/imgs/olive_on_icon.png',
          ingredName: '올리브 오일',
          ingredDesc: '부드러운 피부결 관리 및\n영양공급',
        },
        {
          ingredIconOff: 'assets/imgs/aloe_icon.png',
          ingredIconOn: 'assets/imgs/aloe_on_icon.png',
          ingredName: '알로에',
          ingredDesc: '향균 및 소염, 두피 세척\n두피 보습에 탁월',
        },
        {
          ingredIconOff: 'assets/imgs/rose_icon.png',
          ingredIconOn: 'assets/imgs/rose_on_icon.png',
          ingredName: '장미',
          ingredDesc: '수분 보충 및 ph밸런스 조절',
        },
        {
          ingredIconOff: 'assets/imgs/carrot_icon.png',
          ingredIconOn: 'assets/imgs/carrot_on_icon.png',
          ingredName: '당근',
          ingredDesc: '수분 보충 및 ph밸런스 조절',
        },
        {
          ingredIconOff: 'assets/imgs/jojoba_icon.png',
          ingredIconOn: 'assets/imgs/jojoba_on_icon.png',
          ingredName: '호호바씨',
          ingredDesc: '수분 보충 및 ph밸런스 조절',
        },
      ],
    },
  ];

  // snail: any = [
  //   {
  //     breadcrumb: '홍삼달팽이 골드세럼 & 크림',
  //     mainImgSrc: 'assets/imgs/thumb_08_img01.jpg',
  //     mainImgAlt: '홍삼달팽이 골드세럼 이미지',
  //     koTitle: '홍삼달팽이 골드세럼',
  //     enTitle: 'Red Ginseng Snail Gold Serum',
  // price: '',
  //     weight: '50ml',
  //     descript:
  //       '홍삼을 먹여 키운 달팽이로부터 유래한 달팽이 점액을 함유하여 상처치유 및 피부재생 효과, 피부 자극 완화 효과가 뛰어납니다. 또한 불로장생의 나무, 기적의 나무라 일컫는 모링가 추출물을 함유하고 있으며 인도의 인삼 이라 불리는 아슈와간다 추출물과 금가루를 함유하여 독소, 노폐물 배출 및 피부톤을 개선시켜 줍니다. 산뽕나무줄기추출물, 마치현추출물, 녹차추출물 등 피부 컨디셔닝에 효과적인 성분들이 작용하여 피부 보습 및 진정효과를 부여하고 미백과 주름을 개선하는 2중기능성 세럼입니다.',
  //     visible: false,
  //     youtubeSrc: 'https://www.youtube.com/embed/0k00mIwxIEI?controls=0',
  //     ingreds: [
  //       {
  //         ingredIconOff: 'assets/imgs/redginseng_icon.png',
  //         ingredIconOn: 'assets/imgs/redginseng_on_icon.png',
  //         ingredName: '홍삼',
  //         ingredDesc: '기미 주근깨 등 색소침착 예방\n피부 미백에 도움',
  //       },
  //       {
  //         ingredIconOff: 'assets/imgs/snail_icon.png',
  //         ingredIconOn: 'assets/imgs/snail_on_icon.png',
  //         ingredName: '달팽이 추출물',
  //         ingredDesc: '피부 보호 및 재생효과\n주름 개선 및 수분 보유력 상승',
  //       },
  //       {
  //         ingredIconOff: 'assets/imgs/moringa_icon.png',
  //         ingredIconOn: 'assets/imgs/moringa_on_icon.png',
  //         ingredName: '모링가 추출물',
  //         ingredDesc: '콜라겐 섬유재생을 도움\n주름과 피부 트러블 개선',
  //       },
  //       {
  //         ingredIconOff: 'assets/imgs/ashwagandha_icon.png',
  //         ingredIconOn: 'assets/imgs/ashwagandha_on_icon.png',
  //         ingredName: '아슈와간다',
  //         ingredDesc: '콜라겐 생성 촉진\n및 노화방지에 도움',
  //       },
  //       {
  //         ingredIconOff: 'assets/imgs/greentea_icon.png',
  //         ingredIconOn: 'assets/imgs/greentea_on_icon.png',
  //         ingredName: '녹차 추출물',
  //         ingredDesc: '기미 주근깨 등 색소침착 예방\n피부 미백에 도움',
  //       },
  //       {
  //         ingredIconOff: 'assets/imgs/ginger_icon.png',
  //         ingredIconOn: 'assets/imgs/ginger_on_icon.png',
  //         ingredName: '생강',
  //         ingredDesc: '피부 상태 개선 및 여드름 제거\n화상 완화 효과',
  //       },
  //     ],
  //   },
  // ];

  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe((data: any) => {
      if (data.type == 'shampoo') {
        this.items = this.shampoo;
      } else if (data.type == 'ash') {
        this.items = this.ash;
      } else if (data.type == 'rose') {
        this.items = this.rose;
      } else if (data.type == 'cica') {
        this.items = this.cica;
      } else if (data.type == 'eyePatch') {
        this.items = this.eyePatch;
      } else if (data.type == 'shampooLiquid') {
        this.items = this.shampooLiquid;
      } else if (data.type == 'handCream') {
        this.items = this.handCream;
      }
      // else if (data.type == 'snail') {
      //   this.items = this.snail;
      // }
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.swiperOptions();
    this.thumbImageHoverShow();
  }

  swiperOptions() {
    if (this.items == this.shampoo) {
      let swiper = new Swiper('.swiper-container', {
        speed: 500,
        slidesPerView: 3,
        slidesPerColumn: 1,
        slidesPerGroup: 3,
        spaceBetween: 5,
        loop: true,
        loopFillGroupWithBlank: true,
        // normalizeSlideIndex: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        // grabCursor: true,
        autoplay: false,
        breakpoints: {
          // when window width is >= 1024px
          768: {
            slidesPerView: 4,
            slidesPerColumn: 1,
            slidesPerGroup: 4,
            spaceBetween: 15,
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 6,
            slidesPerColumn: 1,
            slidesPerGroup: 6,
            spaceBetween: 20,
          },
        },
      });
      // console.log('지금은 샴푸바');
    } else if (this.items != this.shampoo) {
      let swiper = new Swiper('.swiper-container', {
        speed: 500,
        slidesPerView: 3,
        slidesPerColumn: 1,
        slidesPerGroup: 3,
        spaceBetween: 5,
        loop: false,
        loopFillGroupWithBlank: true,
        // normalizeSlideIndex: false,
        // grabCursor: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: false,
        breakpoints: {
          // when window width is >= 1024px
          768: {
            slidesPerView: 4,
            slidesPerColumn: 1,
            slidesPerGroup: 4,
            spaceBetween: 15,
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 6,
            slidesPerColumn: 1,
            slidesPerGroup: 6,
            spaceBetween: 20,
          },
        },
      });
      // console.log('지금은 샴푸바가 아니에요');
    }
  }

  thumbImageHoverShow() {
    $('.thumbs-list li a').hover(function () {
      let imgInfo = $(this).children('img').attr('src');
      $('#bigpic').attr({
        src: imgInfo,
      });
      // console.log('여기는 썸네일 하버');
    });
  }
}
