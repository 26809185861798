<header-share></header-share>
<section class="contact-wrap">
  <top-title
    title="모담에 문의하세요"
    sub="모담에 문의하시면 언제든지 친절하게 상담해드립니다"
  ></top-title>

  <article>
    <div class="container">
      <div class="cont-item head-office">
        <div class="stain-bg"></div>
        <div class="main-cont">
          <div class="office-info">
            <span class="contact-cont">
              <span class="cont-inner">
                <span class="contact-title">Head Office</span>
                <span class="contact-address">
                  서울특별시 강남구<br />
                  (도로명주소) 삼성로 76길 23-13 101호<br />
                  (지번주소)대치동 955-3 101호
                </span>
                <span class="remains-info">
                  <span class="tel">02-3453-7133</span><br />
                  <span class="mail">ohjoo114@daum.net</span>
                </span>
              </span>
            </span>
          </div>
          <div class="map head-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.116003108006!2d127.0575526153099!3d37.50518207980951!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca415c417f877%3A0x37b46c73bd0b41c5!2z7ISc7Jq47Yq567OE7IucIOqwleuCqOq1rCDrjIDsuZjrj5kgOTU1LTMgMTAx7Zi4!5e0!3m2!1sko!2skr!4v1619651525266!5m2!1sko!2skr"
              width="100%"
              height="100%"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>

      <div class="cont-item factory-office">
        <div class="deco-bg"></div>
        <div class="main-cont">
          <div class="map factory-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d789.5037921448044!2d127.5247940292619!3d37.67235136024543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35633b3c6a8ff4f9%3A0xc2aca37a3253710d!2z6rK96riw64-EIOqwgO2Pieq1sCDshKTslYXrqbQg7JyE6rOh66asIDYzOQ!5e0!3m2!1sko!2skr!4v1618535209798!5m2!1sko!2skr"
              width="100%"
              height="100%"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
          <div class="office-info">
            <span class="contact-cont">
              <span class="cont-inner">
                <span class="contact-title">Factory Office</span>
                <span class="contact-address">
                  경기도 가평군<br />
                  (도로명주소) 설악면 한서로 326(위곡리)<br />
                  (지번주소) 위곡리 639
                </span>
                <span class="remains-info">
                  <span class="tel">02-3453-7133</span>
                </span>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </article>
</section>
<footer-share></footer-share>
