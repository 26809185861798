import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { ShareModule } from './share/share.module';
import { AboutComponent } from './about/about.component';
import { LabComponent } from './lab/lab.component';
import { GlobalComponent } from './global/global.component';
import { ProductComponent } from './product/product.component';
import { ContactComponent } from './contact/contact.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { RouterModule } from '@angular/router';
import { NgSkeletonModule } from 'ng-skeleton';

@NgModule({
  declarations: [
    AboutComponent,
    LabComponent,
    GlobalComponent,
    ProductComponent,
    ContactComponent,
    ProductDetailComponent,
  ],
  imports: [
    CommonModule,
    SwiperModule,
    ShareModule,
    RouterModule,
    NgSkeletonModule,
  ],
})
export class PageModule {}
