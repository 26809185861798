<header-share></header-share>
<section class="detail-wrap" *ngFor="let item of items">
  <top-title></top-title>
  <article class="product-info">
    <div class="container">
      <div class="breadcrumb">
        <a href="javascript:void(0)" routerLink="/product">제품소개</a>
        <span class="c-line"></span>
        <a href="javascript:void(0)">{{ item.breadcrumb }}</a>
      </div>
      <div class="product-item">
        <div class="img-wrap">
          <div class="bic-imgs">
            <img
              src="{{ item.mainImgSrc }}"
              alt="{{ item.mainImgAlt }}"
              id="bigpic"
            />
          </div>
          <div class="thumbs-list">
            <ul>
              <li *ngFor="let img of item.subImgs">
                <a href="javascript:void(0)">
                  <img src="{{ img.subImg }}" alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-area">
          <h3>{{ item.koTitle }}</h3>
          <p class="en-name">
            {{ item.enTitle }}
          </p>
          <p class="simple-info">
            <!-- <span class="price" *ngIf="items !== snail"
              >{{ item.price }}원</span
            > -->
            <span class="weight">{{ item.weight }}</span>
          </p>
          <p class="ifno-text-title">상세정보</p>
          <p class="info-descript">
            {{ item.descript }}
          </p>
          <div *ngIf="item.visible">
            <div class="info">
              <img src="assets/imgs/youtube_logo.png" alt="" />
              <div class="info-text">
                <p class="sns-name">YOUTUBE</p>
                <p>
                  (주)모담글로벌네이처 공식 유튜브 MODAM Global Nature Co.,Ltd.
                </p>
              </div>
            </div>
            <div class="video-wrap">
              <iframe
                width="100%"
                height="100%"
                [src]="item.youtubeSrc | safe"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="product-item snail-cream" *ngIf="items == snail">
      <div class="img-wrap">
        <div class="bic-imgs">
          <img
            src="assets/imgs/thumb_08_img02.jpg"
            alt="홍삼달팽이 크림"
            id="bigpic"
          />
        </div>
      </div>
      <div class="text-area">
        <h3>홍삼달팽이 크림</h3>
        <p class="en-name">Red Ginseng Snail Gold Cream</p>
        <p class="simple-info">
          <span class="price" *ngIf="items !== snail">{{ item.price }}원</span>
          <span class="weight">50g</span>
        </p>
        <p class="ifno-text-title">상세정보</p>
        <p class="info-descript">
          특허 성분인 홍삼을 먹여 키운 달팽이로부터 유래한 달팽이 점액 여과물이
          30%이상 들어가 있어 각종 외부 환경으로 인해 손상된 피부를 밤시간동안
          케어 해주어 피부 장벽 강화에 도움을 주며 인도의 인삼이라 불리는
          아슈와간다 추출물을 함유하여 항산화 작용, 항균, 미백 효과를 볼 수
          있고, 유용 성분들이 피부 속부터 탄탄하게 채우며 피부탄력에도 효과를
          주는 고수분 고영양 나이트크림입니다.
        </p>
      </div>
    </div> -->
  </article>

  <article id="ingredient-wrap">
    <div class="ingredient container">
      <h2>주요성분</h2>
      <div class="ingredient-inner">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let ingred of item.ingreds">
              <div class="img-area">
                <img src="{{ ingred.ingredIconOff }}" alt="" class="off" />
                <img src="{{ ingred.ingredIconOn }}" alt="" class="on" />
              </div>
              <p class="ingr-name">{{ ingred.ingredName }}</p>
              <p class="ingr-descript">{{ ingred.ingredDesc }}</p>
            </div>
          </div>

          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
  </article>
</section>

<footer-share></footer-share>
