import { Component, OnInit } from '@angular/core';
import SwiperCore, {
  Pagination,
  Autoplay,
  Swiper,
  Navigation,
} from 'swiper/core';
import * as InstagramFeed from '../../../assets/js/InstagramFeed.min.js';
import * as $ from 'jquery';
SwiperCore.use([Pagination, Autoplay, Navigation]);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    this.instaFeedLoad();
    setTimeout(() => {
      let skeleton = document.querySelectorAll('ng-skeleton');
      skeleton.forEach((item) => {
        item.classList.add('hide');
      });
    }, 2400);
    setTimeout(() => {
      this.instaContentChange();
    }, 2300);
    this.swiperOptions();
    // $('body', 'html').scrollTop(0);
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  }

  ngAfterViewInit(): void {}

  instaFeedLoad() {
    new InstagramFeed({
      username: 'modam_gn',
      container: document.getElementById('content-social'),
      display_profile: false,
      display_biography: true,
      display_gallery: true,
      display_captions: true,
      max_tries: 8,
      callback: null,
      styling: true,
      items: 3,
      items_per_row: 1,
      margin: 0,
      lazy_load: false,
      cache_time: 360,
      cdnUrlResolver: function (url) {
        return 'https://modam.modamfirebase.workers.dev/' + url;
      },
      host:
        'https://images' +
        ~~(Math.random() * 3333) +
        '-focus-opensocial.googleusercontent.com/gadgets/proxy?container=none&url=https://instagram.com/',
      // on_error: console.error,
    });
  }
  // https://jhlee.modam.workers.dev

  instaContentChange() {
    let img = $('#content-social a:nth-child(1) img').attr('src');
    let text = $('#content-social a:nth-child(1) img').attr('alt');
    let href = $('#content-social a:nth-child(1)').attr('href');
    let img2 = $('#content-social a:nth-child(2) img').attr('src');
    let text2 = $('#content-social a:nth-child(2) img').attr('alt');
    let href2 = $('#content-social a:nth-child(2)').attr('href');
    let img3 = $('#content-social a:nth-child(3) img').attr('src');
    let text3 = $('#content-social a:nth-child(3) img').attr('alt');
    let href3 = $('#content-social a:nth-child(3)').attr('href');
    $(function () {
      $('.-first').attr('href', href);
      $('.-first img').attr('src', img);
      $('.insta-text.-txtfirst').html(text);
      $('.-second').attr('href', href2);
      $('.-second img').attr('src', img2);
      $('.insta-text.-txtsecond').html(text2);
      $('.-third').attr('href', href3);
      $('.-third img').attr('src', img3);
      $('.insta-text.-txtthird').html(text3);
    });
  }

  swiperOptions() {
    let swiper = new Swiper('.swiper-container', {
      speed: 500,
      slidesPerView: 3,
      slidesPerColumn: 1,
      slidesPerGroup: 1,
      spaceBetween: 10,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      // grabCursor: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      breakpoints: {
        // when window width is >= 1024px
        768: {
          slidesPerView: 4,
          slidesPerColumn: 1,
          slidesPerGroup: 2,
          spaceBetween: 15,
        },
        // when window width is >= 1024px
        1024: {
          slidesPerView: 6,
          slidesPerColumn: 1,
          slidesPerGroup: 2,
          spaceBetween: 20,
        },
      },
    });

    $('.swiper-container').on('mouseenter', function (e) {
      // console.log('stop autoplay');
      swiper.autoplay.stop();
    });
    $('.swiper-container').on('mouseleave', function (e) {
      // console.log('start autoplay');
      swiper.autoplay.start();
    });

    let slide = $('.swiper-slide');
    if (window.matchMedia('(min-width: 0px) and (max-width: 767px)').matches) {
      /* swiper slide auto active */
      let index = swiper.activeIndex + 1;
      if (!swiper.animating) {
        slide[index].classList.add('active');
      }

      swiper.on('realIndexChange', function () {
        let activeI = swiper.activeIndex;

        let main = activeI + 1;
        let prev = activeI;
        let next = activeI + 2;
        if (!swiper.animating) {
          slide[main].classList.add('active');
        }
        slide[prev].classList.remove('active');
        slide[next].classList.remove('active');
      });
    } else if (
      window.matchMedia('(min-width: 0px) and (max-width: 1023px)').matches
    ) {
      /* swiper slide auto active */
      let index = swiper.activeIndex + 1;
      let indexSecond = swiper.activeIndex + 2;
      if (!swiper.animating) {
        slide[index].classList.add('active');
        slide[indexSecond].classList.add('active');
      }

      swiper.on('realIndexChange', function () {
        let activeI = swiper.activeIndex;

        let main = activeI + 1;
        let mainSecond = activeI + 2;
        let prev = activeI;
        let next = activeI + 3;
        if (!swiper.animating) {
          slide[main].classList.add('active');
          slide[mainSecond].classList.add('active');
        }
        slide[prev].classList.remove('active');
        slide[next].classList.remove('active');
      });
    } else {
      /* swiper slide auto active */
      let index = swiper.activeIndex + 2;
      let indexSecond = swiper.activeIndex + 3;
      if (!swiper.animating) {
        slide[index].classList.add('active');
        slide[indexSecond].classList.add('active');
      }

      swiper.on('realIndexChange', function () {
        let activeI = swiper.activeIndex;

        let main = activeI + 2;
        let mainSecond = activeI + 3;
        let prev = activeI;
        let prevSecond = activeI + 1;
        let next = activeI + 4;
        let nextSecond = activeI + 5;
        if (!swiper.animating) {
          slide[main].classList.add('active');
          slide[mainSecond].classList.add('active');
        }
        slide[prev].classList.remove('active');
        slide[prevSecond].classList.remove('active');
        slide[next].classList.remove('active');
        slide[nextSecond].classList.remove('active');
      });
    }
  }
}
