<header-share></header-share>

<section>
  <top-title title="About MODAM" sub="어머니의 마음을 담아서"></top-title>

  <!-- 본문 영역 -->
  <article>
    <div class="container">
      <img src="assets/imgs/content_img.jpg" alt="" />
      <div class="text-area">
        <p class="cont-title">어머니의 마음을 담아서</p>
        <div class="cont-text">
          <p>
            모담은 2005년 스트레스와 각종 이유로 탈모로 고통받는 현대인들에게
            도움을 주고자 설립되었습니다. 두피의 컨디션을 건강하게 유지하고,
            탈모를 방지해주는 샴푸바(bar) 개발을 시작으로, 모담의 노하우를
            바탕으로 특별하고 창의적인 제품을 제작하고 있습니다.
          </p>
          <br />
          <p>
            모담은 한방 약제들을 발효시켜 만든 특수 원료로 고체 샴푸바, 샴푸액을
            개발하고, 인도의 인삼이라 불리는 아슈와간다로 만든 발효 마스크팩,
            고가의 불가리아산 유기농 로즈워터와 로즈 앱솔루트를 넣은 로즈
            <!-- 앱솔루트 마스크팩, 홍삼 먹인 달팽이 점액질로 만든 발효 마스크팩을 -->
            앱솔루트 마스크팩을 추가로 개발하였습니다.
          </p>
          <br />
          <p>
            현재 일본 유명 홈쇼핑에 수출하고 있으며, 미국, 독일, 캄보디아,
            싱가폴 등지에 활발히 수출을 이어가고 있습니다.
          </p>
        </div>
      </div>
    </div>
  </article>
  <!-- // 본문 영역 -->
</section>

<footer-share></footer-share>
